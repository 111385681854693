import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SFStep1 } from './SFStep1';
import { SFStep2 } from './SFStep2';
import { SFStep3 } from './SFStep3';
import SFStep4 from './SFStep4';
import * as DocumentStore from '../store/DocumentStore';
import * as UserSettingsStore from '../store/userManagement/UserSettingStore';
import * as SignatureFlowSettingsStore from '../store/SignatureFlowSettingsStore';
import { ISigningInfo, SignatureFlowMailType, initialSigningInfo, IClient, SenderSignOption, ClientType, DraftState, ILocationMismatchModalState, initialLocationMismatchModalState, SigningType } from '../models/SigningInfo';
import { IDocumentData, DocumentUploadData, IDocumentInfo, getSignersWithoutSignatureControl } from '../models/DocumentInfo';
import * as SFStore from '../store/SFStore';
import * as CompanyStore from '../store/Company/CompanyStore';
import * as Constants from './helper/Constants';
import  moment from "moment";
import * as UserProfileStore from '../store/userManagement/UserProfileStore';
import * as AllUsersStore from '../store/SFUserStore';
import * as ContactsStore from '../store/ContactsStore';
import * as SignatureStore from '../store/SignatureStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { SignatureFlow } from './common/SignatureFlow';
import * as SignatureFlowReportPopupModelStore from '../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import { ISignatureData, ISignatureControl, SignatureControlType, SignatureControlRole, IConditionalControlRule } from '../models/SignatureData';
import { IRecognizedPage, IReconizedDocumentInfo } from '../models/RecognizedDocument';
import * as Helper from './helper/HelperFunctions';
import { getFileExtension, getFileType, isAuthenticationValid, isValidEmailAddress } from './helper/Validations';
import { VenusNotifier } from './helper/VenusNotifier';
import { logger } from '../../routes/LoggedIn';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { loadPdf } from './helper/HelperFunctions';
import { IPdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import { RBACInfo, ResourceIdConstants } from './helper/RBACConstants';
import { Col, Row } from 'react-bootstrap';
import OverlayLoader from './helper/OverlayLoader';
import { AccessOption, IEventNotifySettings, ISignatureFlowSettings, initialEventNotifySettings, initialSignatureFlowSettings } from '../models/SignatureFlowSettings';
import { SignatureReportPaths } from './helper/UrlPaths';
import { UploadStatus } from 'src/Core/ViewModels/Common/UploadDocumentViewModel';
import { CompareClientInfo, addClientInfo, editClientInfo, getClientInfoByClientId } from '../store/ClientManagementAPI';
import * as SignatureFlowSavedMessageStore from '../store/settings/SavedMessage/SavedMessageStore';
import { ClientInfoCallbackEvent, ClientInfoComparisionCode, IClientInfoComparisonResultViewModel, IClientInfoMismatchModal, IClientInfoViewModel, 
        initialClientInfoComparisonResultViewModel, initialClientInfoMismatchModalState, initialClientInfoViewModel, setNewClientInfoComparisonResult} from '../models/ClientManagement';
import ClientInfoMismatchModal from './ClientManagement/ClientInfoMismatchModal';
import {isEmpty, max, trim} from 'lodash';
import LocationMismatchModal from './LocationMismatch/LocationMismatchModal';
import * as OfficeLocationStore from '../store/MultiOffice/OfficeLocationStore';

type SendForSignatureProps = {
    companyData: CompanyStore.ICompanyData,
    sfUploadStore: DocumentStore.IBlobFile,
    signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData,
    documentDataList: IDocumentData[],
    profile: UserProfileStore.IUserProfileData,
    userList: AllUsersStore.ISFUserData,
    contactsStore: ContactsStore.IContactsState,
    settings: UserSettingsStore.UserSettings,
    sendForSignatureData: SFStore.ISendForSignatureData,
    recognizedSignatureFlowDocumentsInfo: IReconizedDocumentInfo[],
    signingInfoDictionary:SignatureStore.ISigningInfoDictionary,
    officeLocations: OfficeLocationStore.IOfficeLocationState,
    userOfficeLocation: OfficeLocationStore.IUserOfficeLocationState,
    SavedMessageData: SignatureFlowSavedMessageStore.ISavedMessageData,
    AuthenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionsData,
}
    & typeof DocumentStore.actionCreators
    & typeof SignatureFlowSettingsStore.actionCreators
    & typeof SFStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof AllUsersStore.actionCreators
    & typeof ContactsStore.actionCreators
    & typeof SignatureStore.actionCreators
    & typeof SignatureFlowReportPopupModelStore.actionCreators
    & typeof SignatureFlowSavedMessageStore.actionCreators
    & typeof SignatureFlowAuthenticationQuestionsStore.actionCreators
    & RouteComponentProps<{}>

const stepsArray = [{ id: 1, text: 'Upload Files' },
{ id: 2, text: 'Add Signers' },
{ id: 3, text: 'Add e-Signatures' },
{ id: 4, text: 'Review & Send' }]

type SendForSignatureState = {
    signingInfo: ISigningInfo;
    signatureFlowSettings: ISignatureFlowSettings;
    currentStep: number;
    destinationStep: number | null;
    documentUploadData: DocumentUploadData[];
    documentData: IDocumentData[];
    recognizedDocuments: IReconizedDocumentInfo[];
    fileUploadProgress: boolean;
    isOutForSignature: boolean;
    showClosePopover: boolean;
    closePopoverMessage: string;
    isRecipientsUpdated: boolean;
    isSaveInProgress:boolean;
    isDraftDocLoading:boolean;
    documentNotifyEventSetting:IEventNotifySettings;
    isNavigatedFromDraft:boolean;
    clientInfo: IClientInfoViewModel;
    clientInfoComparisonResult: IClientInfoComparisonResultViewModel;
    clientInfoMismatchModalState: IClientInfoMismatchModal;
    compareClientInfoProgress: boolean;
    locationMismatchModalState: ILocationMismatchModalState;
    documentAccessCleanUp: boolean;
};
export class SendForSignature extends React.Component<SendForSignatureProps, SendForSignatureState>{
    private step1Ref : any;
    private step4Ref: any;

    constructor(props: SendForSignatureProps) {  
        super(props);  
        this.step4Ref = React.createRef();  
      }  

    state: SendForSignatureState = {
        signingInfo: initialSigningInfo,
        signatureFlowSettings: {...initialSignatureFlowSettings,...initialEventNotifySettings},
        currentStep: 1,
        destinationStep: null,
        documentUploadData: [],
        documentData: [],
        recognizedDocuments: [],
        fileUploadProgress: true,
        isOutForSignature: false,
        showClosePopover: false,
        closePopoverMessage: '',
        isRecipientsUpdated: false,
        isSaveInProgress:false,
        isDraftDocLoading:false,
        documentNotifyEventSetting:null,
        isNavigatedFromDraft:false,
        clientInfo: initialClientInfoViewModel,
        clientInfoComparisonResult: initialClientInfoComparisonResultViewModel,
        clientInfoMismatchModalState: initialClientInfoMismatchModalState,
        compareClientInfoProgress: false,
        locationMismatchModalState: initialLocationMismatchModalState,
        documentAccessCleanUp: false
    }

    UNSAFE_componentWillMount() {
        logger.trackPageView("Signature Home Page");
        this.props.requestAllSFUsers(true);
        this.props.requestUserProfile();
        this.props.getContacts();
        this.props.requestSavedMessages(ResourceIdConstants.SendForSignature);
        this.props.requestCompanySignatureSettings(this.signatureFlowSettingsCallback);
        this.props.requestSignatureFlowUserDelegatee();
        this.props.requestAuthenticationQuestions();
        if(this.props.location.state && this.props.location.state["redirectedFrom"] && this.props.location.state["redirectedFrom"].pathname == '/SignatureDraftReport'){
            this.setState({isDraftDocLoading:true, isNavigatedFromDraft:true});
            this.props.requestSigningInfoDocument(this.props.location.state["signingId"], SignatureReportPaths.SigningInfoWithDocuments, this.modifySendForSignatureState);
        }
        if(this.props.officeLocations.locationDropdown.length == 1)
        {
            this.OnOfficeLocationChange(this.props.officeLocations.locationDropdown[0].value);
        }
    }

    modifySendForSignatureState = () => {
        const signingId = this.props.location.state["signingId"];
        const _signingInfo = this.props.signingInfoDictionary[signingId].signingInfo;
        const _signingFlowSettings = this.props.signingInfoDictionary[signingId].signatureFlowSettings;
        this.setState({documentNotifyEventSetting:_signingFlowSettings.eventNotifySettings});
        const documentInfo = _signingInfo.documentInfo;

        let _documentData: IDocumentData[] = [];
        let _documentUploadData: DocumentUploadData[] = [];

        documentInfo.forEach(doc => {
            _documentData.push({
                documentGuid: doc.documentGuid,
                fileName: doc.name,
                pdf: undefined,
                sasUrl: doc.sasUrl,
                isPDFloaded: false,
                sourceFileGuid: '',
                fileType: getFileType(getFileExtension(doc.name)),
                storageAccountId: doc.storageAccountId
            });

            _documentUploadData.push({
                documentType: doc.documentTypeId,
                expireon: doc.expiresOn,
                gridRowDisable: false,
                name: doc.name,
                number:(_documentUploadData.length) + 1,
                progressBar:100,
                rowIndex:(_documentUploadData.length) + 1,
                sasGuid:doc.documentGuid,
                size:doc.size,
                sourceFileGuid:null,
                status:UploadStatus.Uploaded,
                storageAccountId: doc.storageAccountId
            });
        });

        this.setState(
            { 
                signingInfo: _signingInfo,
                documentUploadData:_documentUploadData,
                documentData:_documentData,
                fileUploadProgress:false,
                isDraftDocLoading:false,
                signatureFlowSettings:this.setSignatureFlowSettingState(_signingFlowSettings),
                currentStep:_signingInfo?.draftState + 1,
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps: SendForSignatureProps) {
       if(JSON.stringify(nextProps.signatureFlowSettingsData.signatureFlowSettings) !== 
          JSON.stringify(this.props.signatureFlowSettingsData.signatureFlowSettings)) {
            this.setState({signatureFlowSettings: this.setSignatureFlowSettingState(nextProps.signatureFlowSettingsData.signatureFlowSettings),
                })
            }
        if(this.props.officeLocations.locationDropdown.length == 1)
        {
            this.OnOfficeLocationChange(this.props.officeLocations.locationDropdown[0].value);
        }
    }

    componentWillUnmount() {
        this.onSFCancel();
        this.props.history.push({state:undefined});
    }

    private signatureFlowSettingsCallback = (data: ISignatureFlowSettings) => {
        const updatedsignatureFlowSettings = this.setSignatureFlowSettingState(data)
        const documentAccess = this.setInitialDocumentAccess(data, this.state.signingInfo);
        this.setState({
            signatureFlowSettings: updatedsignatureFlowSettings,
            signingInfo: {...this.state.signingInfo, documentAccess: documentAccess}
        })
    }

    private setSignatureFlowSettingState = (data: ISignatureFlowSettings) => {
        let notifyId = this.props.settings.signatureSettings?.defaultSettings ? 
        this.props.settings.signatureSettings.defaultSettings.notifyUser :0;
        if(this.state.documentNotifyEventSetting && this.state.documentNotifyEventSetting?.notifyUserId != 0){
            notifyId = this.state.documentNotifyEventSetting?.notifyUserId;
        }
        if(this.props.userList.users.findIndex(c => c.userId == notifyId) == -1) {
            notifyId = 0;
        }
        return {...data,eventNotifySettings:{notifyUserId: notifyId} }
    }

    private setInitialDocumentAccess = (data: ISignatureFlowSettings, signingInfo: ISigningInfo) => {
        let documentAccess = {...signingInfo.documentAccess};

        if(data.accessOptionsSettings.accessOption === AccessOption.Everyone){
            documentAccess = {...documentAccess, userIds: []};
        }
        if(data.accessOptionsSettings.accessOption === AccessOption.IndividualUser){
            documentAccess = {...documentAccess, userIds: [this.props.profile.user.userId]};
        }
        
        return documentAccess;
    }

    private modifyAccessCodeAuthentication = (isEnabled: boolean) => {
        let _signingInfo = this.state.signingInfo;
        _signingInfo.isAccessCodeAuthenticationRequired = isEnabled;
        this.setState({ signingInfo: _signingInfo });
    }

    private OnTaxYearChange = (val: any) => {
        let _signingInfo = { ...this.state.signingInfo };
        _signingInfo.taxYear = val;
        this.setState({ signingInfo: _signingInfo });
    }

    private OnOfficeLocationChange = (val: any) => {
        val = val == null ? 0 : val;
        const locationName = this.props.officeLocations.locationDropdown.find(c => c.value == val)?.name;
        let _signingInfo = { ...this.state.signingInfo };
        let _clientInfo = {...this.state.clientInfo};
        _signingInfo.locationId = val;
        _clientInfo.locationId = val;
        _clientInfo.locationName = locationName;

        this.setState({ 
            signingInfo: _signingInfo, 
            clientInfo: _clientInfo
        }, () => {
            this.onUpdateClientInfoCanShowModal();
        });
    }

    private onUpdateClientInfoCanShowModal = () => {
        let _clientInfoMismatchModalState = {...this.state.clientInfoMismatchModalState};
        let _locationMismatchModalState = {...this.state.locationMismatchModalState};
        _clientInfoMismatchModalState.canShowModal = true;
        _locationMismatchModalState.shouldShowModal = true;
        this.setState({ 
            clientInfoMismatchModalState : _clientInfoMismatchModalState, 
            locationMismatchModalState: _locationMismatchModalState 
        });
    }

    private OnClientNameChange = (val: any) => {
        let _signingInfo = { ...this.state.signingInfo };
        _signingInfo.recipientName = val;
        this.setState({ signingInfo: _signingInfo }); 
        this.onUpdateClientInfoCanShowModal();
    }

    private OnClientIdChange = (val: any) => {
        let _signingInfo = { ...this.state.signingInfo };
        _signingInfo.clientId = val;
        this.setState({ signingInfo: _signingInfo });
        this.onUpdateClientInfoCanShowModal();
    }

    private checkIfRecipientUpdated = (isUpdated: boolean) => {
        this.setState({ isRecipientsUpdated: isUpdated });
    }

    private getBlobLocationYear = () => {
        let year = 0
        if (this.state.signingInfo.documentStatusChangedOn) {
            year = Helper.getYear(this.state.signingInfo.documentStatusChangedOn);
        }

        return year;
    }

    private onDeleteSFDocument = (documentKey: string, storageAccountId: number, callback?: () => void) => {
        this.props.deleteSFDocument(documentKey, this.getBlobLocationYear(), storageAccountId, callback);
        if (this.state.signingInfo.documentInfo.length < 2) {
            logger.trackPageView("Signature Home Page");
        }
    }

    private onGetUploadLink = (url: string, storageAccountId: number, callback?: () => void) => {
        if (this.state.signingInfo.documentInfo.length < 1) {
            logger.trackPageView("Send for Signature");
        }
        this.props.getSFUploadLink(url, this.getBlobLocationYear(), storageAccountId, callback);
    }
    private hasSendForSignatureAccessPermission = () => {
        return this.props.profile.resourceInfo.availableResources.includes(ResourceIdConstants.SendForSignature);
    }

    private onModifyReminderEnabled = (enabled: boolean) => {
        this.setState({signatureFlowSettings: {...this.state.signatureFlowSettings, reminderSettings: {
            signingReminder: {
                enabled: enabled,
                reminderDays: this.state.signatureFlowSettings.reminderSettings.signingReminder.reminderDays
            }
        }}})
    }

    private onModifyReminderDays = (reminderDays: number) => {
        this.setState({signatureFlowSettings: {...this.state.signatureFlowSettings, reminderSettings: {
            signingReminder: {
                enabled: this.state.signatureFlowSettings.reminderSettings.signingReminder.enabled,
                reminderDays: reminderDays
            }
        }}})
    }

    requestDocumentTypes = () => {
        this.props.requestDocumentTypes(ResourceIdConstants.SendForSignature);
    }
    requestStorageAccountId = () => {
        this.props.requestStorageAccountId();
    }
    requestSavedMessages = () => {
        this.props.requestSavedMessages(ResourceIdConstants.SendForSignature);
    }
    hasSetAccessPermission = () => {
        return this.props.profile.resourceInfo.availableResources.includes(ResourceIdConstants.SetAccessInSignature);
    }

    onChangeNotifyUser =(notifyId: number) => {
        this.setState({signatureFlowSettings: {...this.state.signatureFlowSettings, eventNotifySettings:{
            notifyUserId: notifyId
        }}});
    }

    onChangeContactPerson =(userId: number) => {
        this.setState({ 
            signingInfo:{ ...this.state.signingInfo, 
                contactPersonId: userId
            } 
        });
    }
    
    setDocumentAccess = (usersAllowedAccess: number[]) => {
        this.setState({ 
            signingInfo:{ ...this.state.signingInfo, 
                documentAccess: { ...this.state.signingInfo.documentAccess, 
                    userIds: usersAllowedAccess 
                } 
            } 
        });
    }

    modifyDocumentAccessCleanUp = (cleanUp: boolean) => {
        this.setState({documentAccessCleanUp: cleanUp});
    }


    private stepsInfo = () => {
        const uploadBlobYear = this.state.signingInfo.documentStatusChangedOn ? 
                                Helper.getYear(this.state.signingInfo.documentStatusChangedOn) : 0;
                         
        switch (this.state.currentStep) {
            case 1:
                return <SFStep1 onRef={(ref: any) => (this.step1Ref = ref)} getUploadLink={this.onGetUploadLink} deleteUploadedSFDocument={this.onDeleteSFDocument}
                    requestDocumentTypes={this.requestDocumentTypes}
                    documentTypes={this.props.signatureFlowSettingsData && this.props.signatureFlowSettingsData.documentTypes}
                    requestStorageAccountId={this.requestStorageAccountId}
                    storageAccountId={this.props.signatureFlowSettingsData.storageAccountId}
                    accessOptionsSettings={this.state.signatureFlowSettings.accessOptionsSettings}
                    currentStep={this.state.currentStep}
                    documentAccess={this.state.signingInfo.documentAccess}
                    hasSetAccessPermission={this.hasSetAccessPermission()}
                    users={this.props.userList.users}
                    documentUploadData={this.state.documentUploadData}
                    taxYear={this.state.signingInfo.taxYear}
                    clientName={this.state.signingInfo.recipientName}
                    clientId={this.state.signingInfo.clientId}
                    updateDocumentUploadData={this.updateDocumentUploadData}
                    SendDocumentToExtractSignatureControlsAsync={this.props.SendDocumentToExtractSignatureControlsAsync}
                    addDocumentData={this.addDocumentData}
                    deleteDocumentData={this.deleteDocumentData}
                    fileUploadInProgress={this.fileUploadInProgress}
                    updateTaxYear={this.OnTaxYearChange}
                    updateClientName={this.OnClientNameChange}
                    updateClientId={this.OnClientIdChange}
                    isDraftDocLoading={this.state.isDraftDocLoading}
                    getClientInfo={this.onGetClientInfoByClientId}
                    officeLocations={this.props.officeLocations.locationDropdown}
                    updateOfficeLocation={this.OnOfficeLocationChange}
                    selectedOfficeLocationId={this.state.signingInfo.locationId}
                    uploadBlobYear={uploadBlobYear}
                    isNavigatedFromDraft={this.state.isNavigatedFromDraft}
                    setDocumentAccess={this.setDocumentAccess}
                    documentAccessCleanUp={this.state.documentAccessCleanUp}
                    modifyDocumentAccessCleanUp={this.modifyDocumentAccessCleanUp}
                    signatureFlowSettings={this.props.signatureFlowSettingsData.signatureFlowSettings}
                />;
            case 2: return <SFStep2 requestSavedMessages={this.requestSavedMessages}
                updateSFData={this.updateSFData}
                requestCompanySignatureSettings={this.props.requestCompanySignatureSettings}
                companySettings={this.state.signatureFlowSettings}
                users={this.props.userList.users}
                userDelegations={this.props.signatureFlowSettingsData.userDelegatees}
                SavedMessages={this.props.SavedMessageData.savedMessages}
                signingInfo={this.state.signingInfo}
                profile={this.props.profile.user}
                contacts={this.props.contactsStore.contacts}
                company={this.props.companyData.companyProfile}
                questions={this.props.AuthenticationQuestionsData.authenticationQuestions}
                clientAuthenticationData={this.props.sendForSignatureData}
                getAuthenticationParameter={this.props.getAuthenticationParameter}
                isRecipientsUpdated={this.checkIfRecipientUpdated}
            />
            case 3: return <SFStep3 updateSFData={this.updateSFData}
                signingInfo={this.state.signingInfo}
                currentStep={this.state.currentStep}
                documentData={this.state.documentData}
                updateDocumentData={this.updateDocumentData}
                loadRecognizedDataForUnloadedDocuments={this.loadRecognizedDataForUnloadedDocuments}
                loadRecognizedDataForSelectedDocument={this.loadRecognizedDataForSelectedDocument}
                isRecipientsUpdated={this.state.isRecipientsUpdated}
                loadRecognizedDataForSelectedDocumentIfRecipientsUpdated={this.loadRecognizedDataForSelectedDocumentIfRecipientsUpdated}
                isNavigatedFromDraft={this.state.isNavigatedFromDraft}
            />
            case 4: return <SFStep4 ref={this.step4Ref} signingInfo={this.state.signingInfo}
                users={this.props.userList.users} isOutForSignature={this.state.isOutForSignature}
                modifyAccessCodeAuthentication={this.modifyAccessCodeAuthentication}
                userResources={this.props.profile.resourceInfo.availableResources}
                updateClientName={this.OnClientNameChange}
                documentUploadData={this.state.documentUploadData}
                updateClientId={this.OnClientIdChange}
                onChangeNotifyUser ={this.onChangeNotifyUser}
                onChangeContactPerson={this.onChangeContactPerson}
                deleteUploadedSFDocument={this.onDeleteSFDocument}
                requestDocumentTypes={this.requestDocumentTypes}
                deleteDocumentData={this.deleteDocumentData}
                updateDocumentUploadData={this.updateDocumentUploadData}
                documentTypes={this.props.signatureFlowSettingsData && this.props.signatureFlowSettingsData.documentTypes}
                signatureFlowSettings={this.state.signatureFlowSettings}
                onModifyReminderEnabled={this.onModifyReminderEnabled}
                onModifyReminderDays={this.onModifyReminderDays}
                getClientInfo={this.onGetClientInfoByClientId}
                officeLocations={this.props.officeLocations.locationDropdown}
                updateOfficeLocation={this.OnOfficeLocationChange}
                currentUserId={this.props.profile.user.userId}

            />
            default:
                return <SFStep1 ref={this.step1Ref} getUploadLink={this.onGetUploadLink} deleteUploadedSFDocument={this.onDeleteSFDocument}
                    requestDocumentTypes={this.requestDocumentTypes}
                    documentAccess={this.state.signingInfo.documentAccess}
                    hasSetAccessPermission={this.hasSetAccessPermission()}
                    users={this.props.userList.users}
                    documentTypes={this.props.signatureFlowSettingsData && this.props.signatureFlowSettingsData.documentTypes}
                    requestStorageAccountId={this.requestStorageAccountId}
                    storageAccountId={this.props.signatureFlowSettingsData.storageAccountId}
                    accessOptionsSettings={this.state.signatureFlowSettings.accessOptionsSettings}
                    currentStep={this.state.currentStep}
                    documentUploadData={this.state.documentUploadData}
                    taxYear={this.state.signingInfo.taxYear}
                    clientName={this.state.signingInfo.recipientName}
                    clientId={this.state.signingInfo.clientId}
                    updateDocumentUploadData={this.updateDocumentUploadData}
                    SendDocumentToExtractSignatureControlsAsync={this.props.SendDocumentToExtractSignatureControlsAsync}
                    addDocumentData={this.addDocumentData}
                    deleteDocumentData={this.deleteDocumentData}
                    fileUploadInProgress={this.fileUploadInProgress}
                    updateTaxYear={this.OnTaxYearChange}
                    updateClientName={this.OnClientNameChange}
                    updateClientId={this.OnClientIdChange}
                    isDraftDocLoading={this.state.isDraftDocLoading}
                    uploadBlobYear={uploadBlobYear}
                    getClientInfo={this.onGetClientInfoByClientId}
                    officeLocations={this.props.officeLocations.locationDropdown}
                    updateOfficeLocation={this.OnOfficeLocationChange}
                    selectedOfficeLocationId={this.state.signingInfo.locationId}
                    isNavigatedFromDraft={this.state.isNavigatedFromDraft}
                    setDocumentAccess={this.setDocumentAccess}
                    documentAccessCleanUp={this.state.documentAccessCleanUp}
                    modifyDocumentAccessCleanUp={this.modifyDocumentAccessCleanUp}
                    signatureFlowSettings={this.props.signatureFlowSettingsData.signatureFlowSettings}
                />;
        }
    }

    setSFInitialState = () => {
        let signingInfo = initialSigningInfo;
        signingInfo.recipients = [];
        signingInfo.documentAccess = this.setInitialDocumentAccess(this.props.signatureFlowSettingsData.signatureFlowSettings, signingInfo)
        this.setState({
            documentData: [],
            documentUploadData: [],
            currentStep: stepsArray[0].id,
            signingInfo: {
                ...initialSigningInfo,
                locationId: this.props.officeLocations.locationDropdown.length == 1 ? this.props.officeLocations.locationDropdown[0].value : 0,
            },
            isSaveInProgress:false,
            signatureFlowSettings: this.setSignatureFlowSettingState(this.props.signatureFlowSettingsData.signatureFlowSettings),
            isNavigatedFromDraft:false,
            documentAccessCleanUp: true
        })
    }
    onSaveAndClose = () => {
        if(!this.state.isSaveInProgress){
            this.setState({isSaveInProgress:true});
            let signingInfo: ISigningInfo = Object.assign({}, this.state.signingInfo);
            signingInfo.signerAuthentication = this.state.signatureFlowSettings.securitySettings.enableSignerAuthentication;
            signingInfo.mailType = SignatureFlowMailType.Signatures;
            if(signingInfo.contactPersonId !== undefined && signingInfo.contactPersonId == -1){
                signingInfo.contactPersonId = this.props.profile.user.userId;
            }
            else if (signingInfo.contactPersonId == 0 ||
                            (!this.props.userList.isLoading 
                                && this.props.userList.users.find(x=>x.userId == this.state.signingInfo.contactPersonId) == null)){
                signingInfo.contactPersonId = null;
            }
            signingInfo.draftState = (this.state.currentStep-1) as DraftState;
                this.props.saveSigningInfoDocument(this.getSigningInfoPayLoad(signingInfo),
                    this.state.signatureFlowSettings,
                    (type: string) => {
                    this.setState({isSaveInProgress: false, compareClientInfoProgress: false})
                    if (type === SFStore.callbackType.success) {
                        VenusNotifier.Success(Constants.SFDocument.saveSignatureDocument, null);

                        if(this.state.isNavigatedFromDraft){
                            this.props.history.push({ pathname:"/SignatureDraftReport" })
                        }
                        this.setSFInitialState();
                        logger.trackPageView("Signature Home Page");
                    }
                })
        }
    }
   
    onSFCancel = (callback?: any) => {
        if(this.state.isNavigatedFromDraft){
            this.setSFInitialState();
            this.props.unlockDocument(this.state.signingInfo?.id);
            this.props.history.push({ pathname:`${this.props.history.location.pathname}` });
        }
        else if(this.state.documentData?.length >= 1){
            const documentGuids: string[] = this.state.documentData.map(c => c.documentGuid);
            const storageAccountIds: number = this.state.documentData[0].storageAccountId;
            this.props.deleteDocumentsfromBlob(documentGuids, this.state.signingInfo?.id, storageAccountIds, () => {
                this.state.currentStep == 1 && this.step1Ref && this.step1Ref.removeAllValidationErrors();
                this.setSFInitialState();});
        }
        else {
            this.setSFInitialState();
        }
        if (callback)
            callback();
    }

    onExit =()=>{
        if(this.state.isNavigatedFromDraft){
        this.props.history.push({ pathname:"/SignatureDraftReport" })
        }
    }

    onPrevious = (stepNo: number) => {
        this.setState({
            currentStep: stepNo - 1
        })
    }

    onUpdateClientInfoCallbackEvent = (clientInfoCallbackEvent: ClientInfoCallbackEvent) => {
        let newClientInfoMismatchModalState = {...this.state.clientInfoMismatchModalState};
        newClientInfoMismatchModalState.clientInfoCallbackEvent = clientInfoCallbackEvent;
        this.setState({clientInfoMismatchModalState:newClientInfoMismatchModalState}, async () => {
            await this.onCompareClientInfo();
        });
    }

    onNext = (stepNo: number) => {
        if (this.validateSteps(stepNo, this.state.signingInfo)) {
            if(stepNo == 1 && this.state.clientInfoMismatchModalState.canShowModal && 
                !Helper.isNullOrEmpty(this.state.signingInfo?.clientId)){
                this.prepareLocationMismatchCallback(() => {
                    this.onUpdateClientInfoCallbackEvent(ClientInfoCallbackEvent.Next);
                });
            }
            else if(stepNo == 1 && this.state.locationMismatchModalState.shouldShowModal && 
                    Helper.isNullOrEmpty(this.state.signingInfo?.clientId) && 
                    !this.isHaveOfficeLocationAccess()){
                        this.setState({
                            locationMismatchModalState: {
                                isModalOpen: true,
                                shouldShowModal: false,
                                locationMissmatchCallback: () => this.setState({currentStep:stepNo + 1}),
                            }
                        })
            }
            else{
                this.setState({currentStep:stepNo + 1})
            }
        }
    }

    isHaveOfficeLocationAccess = () => {
        return this.props.userOfficeLocation.userLocation.find(i => i.locationId == this.state.signingInfo?.locationId) != undefined;
    }

    prepareLocationMismatchCallback = (callback: () => void) => {
        if(this.isHaveOfficeLocationAccess()){
            callback();
        }
        else {
            this.setState({
                locationMismatchModalState: {
                    isModalOpen: true,
                    shouldShowModal: false,
                    locationMissmatchCallback: callback,
                }
            })
        }
    }

    goTo = (currentStep: number, destinationStep: number) => {
        if (destinationStep < currentStep) {
            this.setState({
                currentStep: destinationStep
            });
        }
        else if (destinationStep > currentStep) {
            let validationFailed = false;

            for (let stepNo = currentStep; stepNo < destinationStep; stepNo++) {
                if (!this.validateSteps(stepNo, this.state.signingInfo)) {
                    this.setState({
                        currentStep: stepNo
                    });
                    validationFailed = true;
                    break;
                }
            }

            if (!validationFailed) {
                this.setState({destinationStep: destinationStep}, () => {
                    if (currentStep == 1 &&
                        this.state.clientInfoMismatchModalState.canShowModal &&
                        !Helper.isNullOrEmpty(this.state.signingInfo?.clientId)) {
                        this.prepareLocationMismatchCallback(() => {
                            this.onUpdateClientInfoCallbackEvent(ClientInfoCallbackEvent.Next);
                        });
                    }
                    else if(currentStep == 1 &&
                        this.state.locationMismatchModalState.shouldShowModal && 
                        Helper.isNullOrEmpty(this.state.signingInfo?.clientId) && 
                        !this.isHaveOfficeLocationAccess()){
                        this.setState({
                            locationMismatchModalState: {
                                isModalOpen: true,
                                shouldShowModal: false,
                                locationMissmatchCallback: () => this.setState({
                                    currentStep: destinationStep,
                                    destinationStep: null
                                })
                            }
                        })
            }
                    else {
                        this.setState({
                                 currentStep: destinationStep,
                                 destinationStep: null
                             });
                    }
                });
            }
        }
    }

    validateSteps = (stepNo: number, signingInfo: ISigningInfo): boolean => {
        switch (stepNo) {
            case 1: if (!this.validateStep1(signingInfo)) { return false; }
                break;
            case 2: if (!this.validateStep2(signingInfo)) { return false; }
                break;
            case 3: if (!this.validateStep3(signingInfo)) { return false; }
                break;
            case 4:
            default:
                if (!this.validateStep1(signingInfo)) { return false; }
                break;
        }
        return true;
    }
    validateStep1 = (signingInfo: ISigningInfo) => {
        if (signingInfo.documentInfo.length < 1) {
            VenusNotifier.Warning(Constants.SFDocument.documentRequired, null);
            return false;
        }
        if (this.state.fileUploadProgress) {
            VenusNotifier.Warning(Constants.SFDocument.uploadInProgress, null);
            return false;
        }
        if (!this.step1_IsDocumentTypeSelected(signingInfo)) {
            return false;
        }
        if (!this.step1_IsDocumentExpireOnSelected(signingInfo)) {
            return false;
        }
        if (!this.step1_IsDocumentExpireOnValid(signingInfo)) {
            return false;
        }
        if(!this.step1_IsClientIdProvided(signingInfo)){
            return false;
        }
        if (!this.step1_IsClientNameProvided(signingInfo)) {
            return false;
        }
        if (!this.step1_IsOfficeLocationProvided(signingInfo)) {
            return false;
        }
        if (!this.step1_IsTaxYearProvided(signingInfo)) {
            return false;
        }
        return true;
    }
    step1_IsDocumentTypeSelected = (signingInfo: ISigningInfo) => {
        for (let i = 0; i < signingInfo.documentInfo.length; i++) {
            if (signingInfo.documentInfo[i].documentTypeId == 0 ||
                signingInfo.documentInfo[i].documentTypeId == null) {
                VenusNotifier.Warning(Constants.SFDocument.documentTypeRequired, null);
                return false;
            }
        }
        return true;
    }
    step1_IsDocumentExpireOnSelected = (signingInfo: ISigningInfo) => {
        for (let i = 0; i < signingInfo.documentInfo.length; i++) {
            if (!moment(signingInfo.documentInfo[i].expiresOn).isValid() || signingInfo.documentInfo[i].expiresOn == undefined ||
                signingInfo.documentInfo[i].expiresOn == null) {
                VenusNotifier.Warning(Constants.SFDocument.documentExpireOnRequired, null);
                return false;
            }
        }
        return true;
    }

    step1_IsDocumentExpireOnValid = (signingInfo: ISigningInfo) => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setMonth(date.getMonth() + 1);

        for (let i = 0; i < signingInfo.documentInfo.length; i++) {
            if (signingInfo.documentInfo[i].expiresOn < date) {
                VenusNotifier.Warning(Constants.SFDocument.documentExpireOnLesser + date.toLocaleDateString(), null);
                return false;
            }
        }
        return true;
    }

    step1_IsClientNameProvided = (signingInfo: ISigningInfo) => {
        if (signingInfo.recipientName == undefined || signingInfo.recipientName.trim() == "") {
            this.step1Ref.showValidationError('ClientName',true);
            return false;
        }
        else {
            return true;
        }
    }

    step1_IsClientIdProvided = (signingInfo: ISigningInfo) => {
        if (signingInfo.clientId == undefined || signingInfo.clientId.trim() == "") {
            if(!this.props.signatureFlowSettingsData.signatureFlowSettings.clientIdSettings.mandateClientId){
                return true;
            }
            this.step1Ref.showValidationError('ClientId',true);
            return false;
        }
        else {
            return true;
        }
    }

    step1_IsOfficeLocationProvided = (signingInfo: ISigningInfo) => {
        if (signingInfo.locationId == undefined || signingInfo.locationId == 0) {
            this.step1Ref.showValidationError('OfficeLocation',true);
            return false;
        }
        else {
            return true;
        }
    }

    step1_IsTaxYearProvided = (signingInfo: ISigningInfo) => {
        if (signingInfo.taxYear == undefined) {
            this.step1Ref.showValidationError('TaxYear',true);
            return false;
        }
        else {
            return true;
        }
    }

    validateStep2 = (signingInfo: ISigningInfo): boolean => {
        let isValid: boolean = true;
        if (signingInfo.recipients.length === 0) {
            isValid = false;
            VenusNotifier.Warning(Constants.SFStep2Constants.RecipientsRequiredWarning, null);
        }
        else if (signingInfo.recipients.filter(r => r.clientType.toString() === ClientType.Signer.toString()).length === 0) {
            isValid = false;
            VenusNotifier.Warning(Constants.SFStep2Constants.EmptySignerWarning, null);
        }

        if(!signingInfo.recipients.map(r => Helper.isNullOrEmpty(r.name)).every((x: boolean) => x == false)){
            isValid = false;
            VenusNotifier.Warning(Constants.SFStep2Constants.EmptyRecipentName, null);
        }
       
        if (!signingInfo.recipients.map((r, i) => {
            return isValidEmailAddress(r.emailAddress)
        }).every((x: boolean) => x == true)) {
            VenusNotifier.Warning(Constants.SFStep2Constants.InvalidEmail, null);
            isValid = false;
        }
        if (!signingInfo.recipients.map((r, i) => {
            return r.clientType !== ClientType.Undefined
        }).every((x: boolean) => x == true)) {
            VenusNotifier.Warning(Constants.SFStep2Constants.RecipientTypeWarning, null);
            isValid = false;
        }

        if (this.state.signatureFlowSettings.securitySettings.enableSignerAuthentication) {
            signingInfo.recipients.forEach((recipient) => {
                if (!isAuthenticationValid(recipient)) {
                    isValid = false;
                    return;
                }
            });
        }

        if (!signingInfo.title || signingInfo.title.trim().length === 0) {
            isValid = false;
            VenusNotifier.Warning(Constants.SFStep2Constants.SubjectRequiredWarning, null);
        }
        if (!signingInfo.message || signingInfo.message.length === 0) {
            isValid = false;
            VenusNotifier.Warning(Constants.SFMessageConstants.MessageRequiredWarning, null);
        }
        
        if (!this.validateSigningOrderForRecipients()) {
            isValid = false;
        }

        return isValid;
    }

    validateStep3 = (signingInfo: ISigningInfo): boolean => {
        if (this.state.documentData.filter(x => x.sasUrl == "").length > 0) {
            VenusNotifier.Warning("We are processing the uploaded word document(s) to fetch your signature controls, Please wait...", null)
            this.loadRecognizedDataForUnloadedDocuments(this.state.documentData.filter(x => x.sasUrl == ""))
            return false
        }
        if (signingInfo.documentInfo.filter(doc => !doc.signatureData || doc.signatureData.length < 1).length > 0) {
            VenusNotifier.Warning(Constants.SFDocument.signatureControlRequired, null);
            return false;
        }

        let isValid: boolean = false;
        let recipientsWithControl: string[] = [];
        let senderInfo: ISignatureControl[] = [];
        let msg: string = '';
        let recipients = signingInfo.recipients.filter(r => r.clientType.toString() === ClientType.Signer.toString()).map(c => c.clientGuid.toString());
        for (let i = 0; i < signingInfo.documentInfo.length; i++) {
            signingInfo.documentInfo[i].signatureData
                .forEach((i: ISignatureData) => {
                    senderInfo = senderInfo.concat(
                        i.signatureControls.filter((j: ISignatureControl) =>
                            (j.type === SignatureControlType.Signature && j.role === SignatureControlRole.SFSender)));

                    i.signatureControls.forEach((j: ISignatureControl) => {
                        if (j.role === SignatureControlRole.SFReceipient && recipientsWithControl.indexOf(j.signer) === -1) {
                            recipientsWithControl.push(j.signer);
                        }
                    });
                });

            if (recipients.every(c => recipientsWithControl.includes(c)) && recipients.length <= recipientsWithControl.length ) {
                if (signingInfo.senderSignOption) {
                    if (senderInfo.length > 0) {
                        isValid = true;
                        break;
                    }
                    msg = Constants.SFDocument.senderSignatureControlRequired;
                } else {
                    isValid = true;
                    break;
                }
            } else {
                msg = Constants.SFDocument.signerControlRequired;
            }
        }

        if (!isValid && msg !== '') {
            VenusNotifier.Warning(msg, null);
        }

        return isValid;
    }

    validateStep4 = () =>{
        if(this.props.signatureFlowSettingsData.signatureFlowSettings.clientIdSettings.mandateClientId && (this.state.signingInfo.clientId === Helper.EMPTY_STRING || this.state.signingInfo.clientId.trim() == "")){
            this.step4Ref.current && this.step4Ref.current.modifyClientIdValidationError(true);
            return false;
        }
        if(this.state.signingInfo.recipientName === Helper.EMPTY_STRING || this.state.signingInfo.recipientName.trim() == ""){
            this.step4Ref.current && this.step4Ref.current.modifyClientNameValidationError(true);
            return false;
        }

        if(!this.state.signingInfo.locationId || this.state.signingInfo.locationId == 0){
            this.step4Ref.current && this.step4Ref.current.modifyLocationValidationError(true);
            return false;
        }

        if (this.step4Ref.current &&  this.step4Ref.current.checkisUpdateOpen()) {
            VenusNotifier.Warning(Constants.ValidationContants.UpdateFileDetails, null);
            return false;
        }
        if(this.state.signingInfo.contactPersonId <= 0 ||
            this.state.signingInfo.contactPersonId == null || 
            this.props.userList.users.find(x=>x.userId == this.state.signingInfo.contactPersonId) == null) {
                VenusNotifier.Warning(Constants.ValidationContants.RequireContactPerson, null);
                return false;
            }
        if(this.state.signatureFlowSettings.eventNotifySettings.notifyUserId <= 0 ||
            this.state.signatureFlowSettings.eventNotifySettings.notifyUserId == null) {
                VenusNotifier.Warning(Constants.ValidationContants.RequireEmailNotify, null);
                return false;
            }
        

        return true;
    }

    validateSigningOrderForRecipients = () => {
        let isValid: boolean = true;
        if(this.state.signingInfo.signingType == SigningType.Custom && this.state.signingInfo.recipients.length > 0){
            this.state.signingInfo.recipients.forEach((recipient) => {
                if (isNaN(recipient.signingOrder)) {
                    isValid = false;
                    if(recipient.emailAddress != null && recipient.emailAddress != ""){
                        VenusNotifier.Warning(Constants.SFStep2Constants.validSigningOrderRecipient.replace('%RECIPIENT%', recipient.emailAddress), "");
                    } else {
                        VenusNotifier.Warning(Constants.SFStep2Constants.validSigningOrder, "");
                    }
                    return;
                }
                else if(recipient.signingOrder == 0) {
                    isValid = false;
                    VenusNotifier.Warning(Constants.SFStep2Constants.validZeroSigningOrder, null);
                    return;
                }
            });
        }
        return isValid;
    }

    onFinishSendForSignature = () => {
        if(!this.validateStep4()){
            return;
        }
        let isValid: boolean = this.validateSigningInfo();
        if (isValid) {
            if(this.state.clientInfoMismatchModalState.canShowModal && 
                !Helper.isNullOrEmpty(this.state.signingInfo?.clientId))
            {
                this.prepareLocationMismatchCallback(() => {
                    this.onUpdateClientInfoCallbackEvent(ClientInfoCallbackEvent.Deliver);
                });
            }
            else if(this.state.locationMismatchModalState.shouldShowModal && 
                Helper.isNullOrEmpty(this.state.signingInfo?.clientId) && 
                !this.isHaveOfficeLocationAccess()){
                this.setState({
                    locationMismatchModalState: {
                        isModalOpen: true,
                        shouldShowModal: false,
                        locationMissmatchCallback: () => this.sendForSignaturee(),
                    }
                })
            }
            else{
                this.sendForSignaturee();
            }
        }
    }

    changeClientSenderSigningOrder = () => {
        if(this.state.signingInfo.senderSignOption != SenderSignOption.NO_SIGN  ){
            this.state.signingInfo.senderSignOption == SenderSignOption.SIGNS_FIRST ? 
                this.state.signingInfo.clientSender.signingOrder = 0 : 
                  this.state.signingInfo.clientSender.signingOrder = this.state.signingInfo.recipients[this.state.signingInfo.recipients.length -  1].signingOrder + 1;
        }
    }

    onSaveSendForSignature = async () => {

        if(!this.validateSigningOrderForRecipients()){
            return;
        }
        if(this.state.signingInfo.locationId == undefined || this.state.signingInfo.locationId == 0 || this.state.signingInfo.locationId == Helper.NO_INDEX){
            this.step4Ref.current && this.step4Ref.current.modifyLocationValidationError(true);
        }
        else if(this.state.clientInfoMismatchModalState.canShowModal &&
            !this.state.compareClientInfoProgress &&
            !Helper.isNullOrEmpty(this.state.signingInfo?.clientId))
        {
            this.prepareLocationMismatchCallback(() => {
                this.onUpdateClientInfoCallbackEvent(ClientInfoCallbackEvent.Save);
            });
        }
        else if(this.state.locationMismatchModalState.shouldShowModal &&
            Helper.isNullOrEmpty(this.state.signingInfo?.clientId) && 
            !this.isHaveOfficeLocationAccess()){
                this.setState({
                    locationMismatchModalState: {
                        isModalOpen: true,
                        shouldShowModal: false,
                        locationMissmatchCallback: () => this.onSaveAndClose(),
                    }
                })
            }
        else{
            this.onSaveAndClose();
        }
    }

    sendForSignaturee = () => {
        const TraceEvent: IEventTelemetry = {
            name: "Send for Signature",
            properties: { count: 1 }
        };
        logger.trackEvent(TraceEvent);
        this.changeClientSenderSigningOrder();
        let signingInfo: ISigningInfo = Object.assign({}, this.state.signingInfo);
        signingInfo.signerAuthentication = this.state.signatureFlowSettings.securitySettings.enableSignerAuthentication;
        signingInfo.mailType = SignatureFlowMailType.Signatures;
        this.setState({ isOutForSignature: true, showClosePopover: false }, () => {
            this.props.sendForSignature(this.getSigningInfoPayLoad(signingInfo),
                this.state.signatureFlowSettings,
                (type: string) => {
                this.setState({ isOutForSignature: false });
                if (type === SFStore.callbackType.success) {
                    VenusNotifier.Success(Constants.SFDocument.outForSignature, null);
                    this.resetSFData();
                    this.setState({
                        documentData: [],
                        documentUploadData: [],
                        currentStep: stepsArray[0].id,
                        signingInfo: {
                            ...initialSigningInfo,
                            locationId: this.props.officeLocations.locationDropdown.length == 1 ? this.props.officeLocations.locationDropdown[0].value : 0,
                        },
                        signatureFlowSettings: this.setSignatureFlowSettingState(this.props.signatureFlowSettingsData.signatureFlowSettings),
                        compareClientInfoProgress: false
                    });

                    if(this.state.isNavigatedFromDraft){
                        this.props.history.push({ pathname:"/SignatureDraftReport" })
                    }
                    logger.trackPageView("Signature Home Page");
                }
                else if(type === SFStore.callbackType.documentProcessedError){
                    this.props.history.push({ pathname:"/SignatureDraftReport" })
                }
            })
        });

        logger.trackTrace("sendForSignaturee with signingInfo" + JSON.stringify(signingInfo));
    }

    //Will be removed in future, due to draft resume dependency added separately
    getSigningInfoPayLoad = (signingInfo):ISigningInfo => {
        let _signingInfo:ISigningInfo = {
            id: signingInfo.id,
            CC: signingInfo.CC ? signingInfo.CC : [],
            sender: signingInfo.sender,
            recipients: signingInfo.recipients,
            senderSignOption: signingInfo.senderSignOption,
            documentAccess: signingInfo.documentAccess,
            documentInfo: signingInfo.documentInfo,
            mailType: signingInfo.mailType,
            title: signingInfo.title,
            clientSender: signingInfo.clientSender,
            isAccessCodeAuthenticationRequired: signingInfo.isAccessCodeAuthenticationRequired,
            recipientName: signingInfo.recipientName,
            taxYear: signingInfo.taxYear,
            clientId: signingInfo.clientId,
            draftState: signingInfo.draftState,
            signerAuthentication: signingInfo.signerAuthentication,
            messageId: signingInfo.messageId,
            message: signingInfo.message,
            delegate: signingInfo.delegate,
            signingType: signingInfo.signingType,
            assignedTo: signingInfo.assignedTo,
            documentStatusChangedOn: signingInfo.documentStatusChangedOn,
            locationId: signingInfo.locationId,
            contactPersonId: signingInfo.contactPersonId
        };
        return _signingInfo;
    }

    validateSigningInfo = (): boolean => {

        let signersWithoutSignControls: IClient[] = getSignersWithoutSignatureControl(this.state.signingInfo.documentInfo,
            this.state.signingInfo.recipients.filter(r => r.clientType.toString() === ClientType.Signer.toString()));

        if (signersWithoutSignControls.length > 0) {

            let signers: string[] = signersWithoutSignControls.map((value: IClient) => {
                return "<span style=\"color: blue;\">" + value.emailAddress + "</span>";
            });

            this.setState({
                showClosePopover: true, closePopoverMessage: Constants.SignatureFlowConstants.SignatureFieldNotPlacedWarningMsg
                    .replace(Constants.SignatureFlowConstants.SignatureFieldNotPlacedWarningMsgPlaceholder, signers.join(', '))
            });
            return false;
        }
        return true;
    }

    fileUploadInProgress = (inProgress: boolean) => {
        this.setState({
            fileUploadProgress: inProgress
        });
    }

    loadRecognizedDataForUnloadedDocuments = (documents: IDocumentData[]) => {
        this.state.documentData.map((x: IDocumentData) => {
            const recogDoc: IReconizedDocumentInfo = this.props.recognizedSignatureFlowDocumentsInfo
                .filter(r => r.documentGuid == x.documentGuid)[0]
            if ((x.sasUrl == "" || x.sasUrl == undefined) && recogDoc) {
                x.sasUrl = recogDoc.recognizedDocumentDetails.uri
            }
        });
        this.populateRecognizedSignatureControlForUnLoadedDocuments(documents);
    }
 
    loadRecognizedDataForSelectedDocument = (documentGuid: string) => {
        if (this.props.recognizedSignatureFlowDocumentsInfo && this.props.recognizedSignatureFlowDocumentsInfo.length > 0) {
            const recogDoc: IReconizedDocumentInfo = this.props.recognizedSignatureFlowDocumentsInfo
                                                                .filter(z => z.documentGuid == documentGuid)[0]
            if (recogDoc) {
                this.state.documentData.map((x: IDocumentData) => {
                    if (x.documentGuid === documentGuid && (x.sasUrl == "" || x.sasUrl == undefined) && recogDoc.recognizedDocumentDetails.uri.length > 0) {
                        x.sasUrl = recogDoc.recognizedDocumentDetails.uri;
                        if(!x.isPDFloaded && !x.pdf){
                            loadPdf(x.sasUrl).then((data: IPdfDocumentFacade) => {
                                x.pdf = data;
                                x.isPDFloaded = true;
                                this.updateDocumentData(x);
                            }).catch(err => {
                                logger.trackError(`Error occurred at loadPDF :  ${JSON.stringify(err)}`);
                            })
                        }
                    }
                });

                this.populateRecognizedSignatureControl(documentGuid, recogDoc.recognizedDocumentDetails.recognizedPages)
            }
        }
    }

    loadRecognizedDataForSelectedDocumentIfRecipientsUpdated = (documentGuid: string) => {
        if (this.props.recognizedSignatureFlowDocumentsInfo && this.props.recognizedSignatureFlowDocumentsInfo.length > 0) {
            const recogDoc: IReconizedDocumentInfo = this.props.recognizedSignatureFlowDocumentsInfo
                                                                .filter(z => z.documentGuid == documentGuid)[0]
            if (recogDoc) {
                this.populateRecognizedSignatureControl(documentGuid, recogDoc.recognizedDocumentDetails.recognizedPages)
            }
        }
        this.removeUnusedSignatureControls();
    }

    private populateRecognizedSignatureControl = (documentGuid: string, recognizedPages: IRecognizedPage[]) => {

        let temp_sfData: ISigningInfo = Object.assign({}, this.state.signingInfo);
        const heightIncrement = 20;
        const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === documentGuid);

        if (documentInfoIndex != Helper.NO_INDEX) {
            let documentInfo = temp_sfData.documentInfo[documentInfoIndex];

            if (!documentInfo.signatureData || !Array.isArray(documentInfo.signatureData)) {
                documentInfo.signatureData = [];
            }
            recognizedPages.forEach(page => {

                let signatureControls: ISignatureControl[];

                const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === page.pageNumber);
                if (signatureDataIndex == Helper.NO_INDEX) {
                    signatureControls = [];
                    let signatureData = { pageNo: page.pageNumber, signatureControls: signatureControls } as ISignatureData;
                    documentInfo.signatureData.push(signatureData);
                }
                else {
                    signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                }

                page.signatureControls.forEach(control => {
                    let signerValue: string = '';
                    if (control.role === SignatureControlRole.SFReceipient) {
                        let signer = parseInt(control.signer);
                        if (signer && temp_sfData.recipients.length >= signer) {
                            signerValue = temp_sfData.recipients[signer - 1].clientGuid.toString();
                        }
                    } else if (control.role === SignatureControlRole.SFSender && temp_sfData.senderSignOption != SenderSignOption.NO_SIGN) {
                        signerValue = temp_sfData.clientSender.clientGuid.toString();
                    }

                    if (signerValue) {
                        if (!control.appliedToDocument) {
                            let newControl: ISignatureControl = {} as ISignatureControl;
                            newControl.controlGuid = control.controlGuid;
                            newControl.signer = signerValue;
                            newControl.type = control.type;
                            newControl.role = control.role;
                            newControl.top = control.top;
                            newControl.left = control.left;
                            newControl.required = control.required;
                            newControl.height = this.setRecognizedSignatureControlDimensions('height', control);
                            newControl.width = this.setRecognizedSignatureControlDimensions('width', control);
                            newControl.items = control.items;
                            newControl.tooltip = control.tooltip;
                            newControl.groupId = control.groupId ? control.groupId : 0;
                            signatureControls.push(newControl);

                            control.appliedToDocument = true;
                        }
                        else {
                            let newControl = signatureControls.find(c => c.controlGuid === control.controlGuid);
                            if (newControl) {
                                newControl.signer = signerValue;
                            }
                        }
                    }
                    else {
                        control.appliedToDocument = false;
                    }
                });
            });           
        }

        this.updateSFData(temp_sfData);
    }

    private setRecognizedSignatureControlDimensions = (type: string , control: ISignatureControl) => {
        if(type == 'width'){
            return control.type === SignatureControlType.Radio || control.type === SignatureControlType.Text ? control.width : null;
        }
        else if(type == 'height'){
            return control.type === SignatureControlType.Radio ? max([Constants.SignatureControlConstants.MinimumHeightToViewDragAndEditIcon, control.height]) :
                        control.type === SignatureControlType.Text ? control.height : null;
        }
    }

    private populateRecognizedSignatureControlForUnLoadedDocuments = (unLoadedControlDocuments: IDocumentData[]) => {

        let temp_sfData: ISigningInfo = Object.assign({}, this.state.signingInfo);

        unLoadedControlDocuments.forEach((document: IDocumentData) => {
            const recognizedDocument: IReconizedDocumentInfo = this.props.recognizedSignatureFlowDocumentsInfo
                .filter(r => r.documentGuid == document.documentGuid)[0];
            if (recognizedDocument) {
                const documentInfoIndex = temp_sfData.documentInfo.findIndex(x => x.documentGuid === recognizedDocument.documentGuid);

                if (documentInfoIndex != Helper.NO_INDEX) {
                    let documentInfo = temp_sfData.documentInfo[documentInfoIndex];

                    if (!documentInfo.signatureData || !Array.isArray(documentInfo.signatureData)) {
                        documentInfo.signatureData = [];
                    }

                    recognizedDocument.recognizedDocumentDetails.recognizedPages.forEach(page => {

                        let signatureControls: ISignatureControl[];

                        const signatureDataIndex = documentInfo.signatureData.findIndex(x => x.pageNo === page.pageNumber);
                        if (signatureDataIndex == Helper.NO_INDEX) {
                            signatureControls = [];
                            let signatureData = { pageNo: page.pageNumber, signatureControls: signatureControls } as ISignatureData;
                            documentInfo.signatureData.push(signatureData);
                        }
                        else {
                            signatureControls = documentInfo.signatureData[signatureDataIndex].signatureControls;
                        }

                        page.signatureControls.forEach(control => {
                            let signerValue: string = '';
                            const heightIncrement = 20;
                            if (control.role === SignatureControlRole.SFReceipient) {
                                let signer = parseInt(control.signer);
                                if (signer && temp_sfData.recipients.length >= signer) {
                                    signerValue = temp_sfData.recipients[signer - 1].clientGuid.toString();
                                }
                            } else if (control.role === SignatureControlRole.SFSender && temp_sfData.senderSignOption != SenderSignOption.NO_SIGN) {
                                signerValue = temp_sfData.clientSender.clientGuid.toString();
                            }

                            if (signerValue) {
                                if (!control.appliedToDocument) {
                                    let newControl: ISignatureControl = {} as ISignatureControl;
                                    newControl.controlGuid = control.controlGuid;
                                    newControl.signer = signerValue;
                                    newControl.type = control.type;
                                    newControl.role = control.role;
                                    newControl.top = control.top;
                                    newControl.left = control.left;
                                    newControl.required = control.required;
                                    newControl.height = this.setRecognizedSignatureControlDimensions('height', control);
                                    newControl.width = this.setRecognizedSignatureControlDimensions('width', control);
                                    newControl.items = control.items;
                                    newControl.tooltip = control.tooltip;
                                    newControl.groupId = control.groupId ? control.groupId : 0;
                                    signatureControls.push(newControl);

                                    control.appliedToDocument = true;
                                }
                                else {
                                    let newControl = signatureControls.find(c => c.controlGuid === control.controlGuid);
                                    if (newControl) {
                                        newControl.signer = signerValue;
                                    }
                                }
                            }
                        });
                    });
                }
            }
        });

        this.updateSFData(temp_sfData);
    }

    addDocumentData = (documentData: IDocumentData) => {
        let tempDocumentData = this.state.documentData;
        tempDocumentData.push(documentData);
        this.state.documentData.map((document: IDocumentData, i: number) => {
            const index = this.state.documentData.findIndex((x: any) => x.name == document.fileName);
            if (index > -1) {
                tempDocumentData.splice(index, 0, document)
            }
        });
        this.setState({ documentData: tempDocumentData });   
    }

    updateDocumentData = (documentData: IDocumentData) => {
        let tempDocumentData: IDocumentData[] = Object.assign([], [...this.state.documentData])
        for (var i = 0; i < tempDocumentData.length; i++) {
            if (tempDocumentData[i].documentGuid === documentData.documentGuid) {
                tempDocumentData[i].pdf = documentData.pdf;
                tempDocumentData[i].isPDFloaded = documentData.isPDFloaded;
                tempDocumentData[i].sourceFileGuid = documentData.sourceFileGuid;
                this.setState({
                    documentData: tempDocumentData
                });
                return;
            }
        }
    }

    deleteDocumentData = (documentGuid: string) => {
        let tempDocumentData: IDocumentData[] = Object.assign([], [...this.state.documentData]);
        tempDocumentData = tempDocumentData.filter(x => x.documentGuid != documentGuid);
        this.props.deleteRecognizedDocumentData(documentGuid);
        tempDocumentData.length == 0 && this.state.currentStep == 1 && this.step1Ref && this.step1Ref.removeAllValidationErrors();
        this.setState({ documentData: tempDocumentData });
    }

    private updateDocumentUploadData = (documentUploadData: DocumentUploadData[]) => {
        // ordering files based on uploaded order
        const tempDocumentData: IDocumentData[] = [];
        this.state.documentData.map((document: IDocumentData, index: number) => {
            tempDocumentData.splice(documentUploadData.findIndex(x => x.name == document.fileName), 0,
                document)
        });

        this.setState({ documentUploadData: documentUploadData, documentData: tempDocumentData }, () => {
            let existingDocumentInfo = this.state.signingInfo.documentInfo;
            let newDocumentInfo = this.state.documentUploadData.map((item: DocumentUploadData, index: number) => {
                let signatureData: ISignatureData[] = [];
                let conditionalControlsRules : IConditionalControlRule[] = [];
                if (existingDocumentInfo) {
                    const documentIndex = existingDocumentInfo.findIndex((d: IDocumentInfo) => d.documentGuid == item.sasGuid);
                    if (documentIndex != Helper.NO_INDEX) {
                        signatureData = existingDocumentInfo[documentIndex].signatureData;
                        conditionalControlsRules = existingDocumentInfo[documentIndex]?.conditionalControlsRules;
                    }
                }

                return {
                    documentGuid: item.sasGuid,
                    documentTypeId: item.documentType,
                    name: item.name,
                    expiresOn: item.expireon,
                    sourceFileGuid: item.sourceFileGuid,
                    signatureData: signatureData,
                    size: item.size,
                    conditionalControlsRules: conditionalControlsRules as IConditionalControlRule[],
                    storageAccountId: item.storageAccountId
                } as IDocumentInfo
            });

            let tempData: ISigningInfo = { ...this.state.signingInfo };
            tempData.documentInfo = newDocumentInfo;
            this.updateSFData(tempData);
        });
    }

    updateSFData = (signingInfo: ISigningInfo) => {
        this.setState({ signingInfo: signingInfo });
    }

    resetSFData = () => {
        let signingInfo = initialSigningInfo;
        signingInfo.recipients = [];
        signingInfo.documentAccess = this.setInitialDocumentAccess(this.props.signatureFlowSettingsData.signatureFlowSettings, signingInfo)
        this.setState({ signingInfo: signingInfo});
    }

    handleShowClosePopoverState = (show: boolean) => {
        this.setState({ showClosePopover: show });
    }

    private removeUnusedSignatureControls = () => {
        let temp_sfData: ISigningInfo = Object.assign({}, this.state.signingInfo);

        let signerIds = temp_sfData.recipients.map(r => r.clientGuid.toString());
        if (temp_sfData.senderSignOption != SenderSignOption.NO_SIGN) {
            signerIds.push(temp_sfData.clientSender.clientGuid.toString());
        }

        temp_sfData.documentInfo.forEach((documentInfo: IDocumentInfo, index) => {

            if (documentInfo.signatureData && Array.isArray(documentInfo.signatureData)) {

                documentInfo.signatureData.forEach(signatureData => {
                    signatureData.signatureControls = signatureData.signatureControls.filter(control => signerIds.indexOf(control.signer) > -1);

                });
            }
        });

        this.updateSFData(temp_sfData);
    }

    onGetClientInfoByClientId = () => {
        getClientInfoByClientId(this.state.signingInfo.clientId, ResourceIdConstants.SendForSignature, (data: IClientInfoViewModel) => {
            let _signingInfo = this.state.signingInfo;
            let _locationMismatchModalState = this.state.locationMismatchModalState;
            if(data && !isEmpty(data) && !isEmpty(data.name) && trim(data.name).length > 0){
                _signingInfo.recipientName = data.name;
                _signingInfo.locationId = data.locationId;
                _locationMismatchModalState.shouldShowModal = true;
                this.setState({
                    clientInfo: data,
                    signingInfo: _signingInfo
                })
            }
        })
    }

    onAddClientInfo = () => {
        addClientInfo(this.getUpdatedClientInfo(true), ResourceIdConstants.SendForSignature, () => {
            this.onGoToClientInfoCallbackEvent();
            let _clientInfoMismatchModalState = initialClientInfoMismatchModalState;
            let _locationMismatchModalState = {...this.state.locationMismatchModalState};
            _clientInfoMismatchModalState.canShowModal = false;
            _locationMismatchModalState.shouldShowModal = false
            this.setState({
                clientInfo: initialClientInfoViewModel,
                clientInfoMismatchModalState: _clientInfoMismatchModalState,
                locationMismatchModalState: _locationMismatchModalState
            })
        });
    }

    onEditClientInfo = () => {
        editClientInfo(this.getUpdatedClientInfo(), ResourceIdConstants.SendForSignature, () => {
            this.onGoToClientInfoCallbackEvent();
            let _clientInfoMismatchModalState = initialClientInfoMismatchModalState;
            let _locationMismatchModalState = {...this.state.locationMismatchModalState};
            _clientInfoMismatchModalState.canShowModal = false;
            _locationMismatchModalState.shouldShowModal = false;
            this.setState({ 
                clientInfo: initialClientInfoViewModel, 
                clientInfoMismatchModalState: _clientInfoMismatchModalState,
                locationMismatchModalState: _locationMismatchModalState
            })
        })
    }

    getUpdatedClientInfo = (create?: boolean) => {
        const locationName = this.props.officeLocations.locationDropdown.find(c => c.value == this.state.signingInfo.locationId)?.name;
        let _clientInfo = {...this.state.clientInfo};
        _clientInfo.name = this.state.signingInfo.recipientName;
        _clientInfo.clientId = trim(this.state.signingInfo.clientId);
        _clientInfo.locationId = this.state.signingInfo.locationId;
        _clientInfo.locationName = locationName;
        if(create) {
            _clientInfo = Helper.cleanClientManagementModal(_clientInfo);
        }
        return _clientInfo;
    }

    onCompareClientInfo = async () => {
        this.setState({clientInfo:this.getUpdatedClientInfo(), compareClientInfoProgress: true}, async () => {
            await CompareClientInfo(this.state.clientInfo, ResourceIdConstants.SendForSignature, this.compareClientInfoCallback)
        })
    }

    onGoToClientInfoCallbackEvent = () => {
        switch(this.state.clientInfoMismatchModalState.clientInfoCallbackEvent){
            case ClientInfoCallbackEvent.None:
                return;
            case ClientInfoCallbackEvent.Next:
            {
                this.setState({
                    currentStep:this.state.destinationStep > 0 ? this.state.destinationStep : this.state.currentStep+1
                }, () => {
                    this.setState({destinationStep:null, compareClientInfoProgress: false})
                })
                break;
            }
            case ClientInfoCallbackEvent.Save:
            {
                this.onSaveAndClose();
                break;
            }
            case ClientInfoCallbackEvent.Deliver:
            {
                this.sendForSignaturee();
                break;
            }
        }
    }

    compareClientInfoCallback = (compareClientInfoData: IClientInfoComparisonResultViewModel, clientInfo: IClientInfoViewModel) => {
        if(compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NoDataMismatch){
            this.onGoToClientInfoCallbackEvent();
        }
        else if(compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NewClient){
            this.newClientCallback(clientInfo);
        }
        else {
            this.clientDataMisMatchCallback(compareClientInfoData, clientInfo);
        }
    }

    setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResultViewModel) => {
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ 
                clientInfoMismatchModalState: { 
                    showModal: true, 
                    isLoading: false,
                    clientBasicInfoId: 0,
                    clientInfoCallbackEvent: this.state.clientInfoMismatchModalState.clientInfoCallbackEvent
                } 
            });
        });
    }
    
    newClientCallback = (clientInfo: IClientInfoViewModel) => {
        this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
    }

    clientDataMisMatchCallback = (compareClientInfoData:IClientInfoComparisonResultViewModel, clientInfo: IClientInfoViewModel) => {
        getClientInfoByClientId(clientInfo.clientId, ResourceIdConstants.SendForSignature, (data: IClientInfoViewModel) => {
            this.setState({clientInfoComparisonResult: compareClientInfoData,
                clientInfo: data,
                clientInfoMismatchModalState: { 
                    showModal: true, 
                    isLoading: false,
                    clientBasicInfoId: data.clientBasicInfoId,
                    clientInfoCallbackEvent: this.state.clientInfoMismatchModalState.clientInfoCallbackEvent
                }
            })
        })
    }

    clientInfoMismatchModalClose = (canUpdate:boolean = false) => {
        let _clientInfoMismatchModalState = initialClientInfoMismatchModalState;
        let _locationMismatchModalState = {...this.state.locationMismatchModalState};
        _clientInfoMismatchModalState.canShowModal = true;
        _locationMismatchModalState.shouldShowModal = true;
        if(canUpdate){
            this.onGoToClientInfoCallbackEvent();
            _clientInfoMismatchModalState.canShowModal = false;
            _locationMismatchModalState.shouldShowModal = false;
        }
        this.setState({ 
            clientInfoMismatchModalState: initialClientInfoMismatchModalState, 
            compareClientInfoProgress: false, 
            locationMismatchModalState: _locationMismatchModalState
        });
    }

    locationMismatchModalClose = () => {
        this.setState({ 
            locationMismatchModalState: {
                ...initialLocationMismatchModalState, 
                shouldShowModal: true
            }}
        );
    }

    locationMismatchModalContinue = () => {
        this.state.locationMismatchModalState.locationMissmatchCallback();
        this.setState({ locationMismatchModalState: initialLocationMismatchModalState });
    }
    
    onCancel = () => {
        this.onSFCancel(this.onExit)
    }

    public render() {
        const hasSendForSignatureAccessPermission = this.hasSendForSignatureAccessPermission();
        return (!this.props.profile.resourceInfo.isLoaded ?
            <OverlayLoader show={!this.props.profile.resourceInfo.isLoaded } text={'Send For Signature is loading. Please wait...'} />
            : this.props.profile.resourceInfo.availableResources.length > 0 &&
            <div>
                <div data-resource-id={ResourceIdConstants.SendForSignature}>
                    <SignatureFlow 
                    steps={stepsArray} 
                    onCancel={() => this.onCancel()} 
                    onSaveAndClose={this.onSaveSendForSignature} 
                    onPrevious={this.onPrevious} 
                    onNext={this.onNext}
                    stepsInfo={this.stepsInfo} 
                    currentStep={this.state.currentStep} 
                    onFinish={this.onFinishSendForSignature}
                    isCustomScrollEnabled={this.state.currentStep == 3 ? true : false} 
                    showClosePopover={this.state.showClosePopover}
                    closePopoverMessage={this.state.closePopoverMessage} 
                    onClosePopoverYesClick={this.sendForSignaturee}
                    setShowClosePopover={this.handleShowClosePopoverState} 
                    goTo={this.goTo} 
                    isOutForSignature={this.state.isOutForSignature} 
                    isSaveInProgress={this.state.isSaveInProgress || this.state.compareClientInfoProgress} 

                    />

                    <ClientInfoMismatchModal 
                        show={this.state.clientInfoMismatchModalState.showModal}
                        clientInfo={this.state.clientInfo}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        clientInfoMismatchModalClose={this.clientInfoMismatchModalClose}
                        isLoading={this.state.clientInfoMismatchModalState.isLoading}
                        onAddClientInfo={this.onAddClientInfo}
                        onUpdateClientInfo={this.onEditClientInfo}
                    />

                    <LocationMismatchModal
                        show={this.state.locationMismatchModalState.isModalOpen}
                        onCancel={this.locationMismatchModalClose}
                        onContinue={this.locationMismatchModalContinue}
                        locationName={this.props.officeLocations.locationDropdown.find(l => l.value == this.state.signingInfo.locationId)?.name}
                    />
                </ div>
            {!hasSendForSignatureAccessPermission && <Row> <Col sm={12} className="txt-ac" style={{ position: "absolute", top: "21rem", zIndex: "" }}> <h6> {RBACInfo.NoPermission} </h6> </Col> </Row>}
        </div>
            
        );
    }
}
