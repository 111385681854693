import * as React from 'react';
import { DocumentUploadDropzoneComponent } from './DocumentUploadDropzoneComponent';
import { ProgressBar, Col, Row, FormControl,Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { VenusNotifier } from '../helper/VenusNotifier';
import { UploadFunctions, isFileExist } from "@sssuite-js-packages/file-utility";
import { RBACInfo} from '../helper/RBACConstants';
import {
    getFileExtension, validateSFUploadFileType, validateWordAndPDFFileContent,validateEncryption,
    validateFileSize, isWordFile
} from '../helper/Validations';
import { getFileSize, GetFileMagicNumber, getCurrentTaxYear } from '../helper/HelperFunctions';
import { SFDocument } from '../helper/Constants';
import { SasContainer, UploadStatus } from '../../../Core/ViewModels/Common/UploadDocumentViewModel';
import * as SFUploadStore from '../../store/DocumentStore';
import { DropdownComponent } from './DropdownComponent';
import * as bootbox from 'bootbox';
import { CustomDatePicker } from './CustomDatePicker';
import { IDocumentType, ISignatureFlowSettings } from '../../models/SignatureFlowSettings';
import { IDocumentData, DocumentUploadData, FileType,IDocumentFileData } from '../../models/DocumentInfo';
import { Guid } from '../../../Core/Utilities/Guid';
import  moment from "moment";
import * as Helper from '../helper/HelperFunctions';
import { isArray } from 'lodash';
import { CalendarCheck, DropdownArrow, PaperClip, SetAccessHeaderIcon, XLg } from '../svg/CustomSvgIcons';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { debounce, trim } from 'lodash';
import { IDropdown } from '../../../Core/ViewModels/User/UserViewModel';

const isEqual = require("react-fast-compare");

const djsConfig: any = {
    uploadMultiple: true,
    addRemoveLinks: true,
    headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
    previewsContainer: false,
    autoProcessQueue: false,
    autoDiscover: false
}


export interface UploadDocumentProps {
    getUploadLink: (url: string, storageAccountId: number, callback?: (data?: SFUploadStore.IBlobFile) => void) => void;
    deleteUploadedSFDocument: (documentKey: string, storageAccountId: number, callback?: () => void) => void;
    requestDocumentTypes: () => void;
    documentTypes: IDocumentType[];
    updateDocumentUploadData: (documentInfo: DocumentUploadData[]) => void;
    documentUploadData: DocumentUploadData[];
    currentStep: number;
    maxFileLimitUpload: number;
    SendDocumentToExtractSignatureControlsAsync: (documentGuid: string, sourceFileGuid: string, uploadBlobYear: number, storageAccountId: number, callback?: (data?: any) => void) => void;
    addDocumentData: (documentData: IDocumentData) => void;
    deleteDocumentData: (documentGuid: string) => void;
    fileUploadInProgress?: (status: boolean) => void;
    updateTaxYear: (taxYear: any) => void;
    updateClientName: (clientName: string) => void;
    updateClientId: (clientId: string) => void;
    taxYear: number;
    clientName: string;
    clientId: string;
    onDocumentAccessOpen: () => void;
    onRef?: (ref: any) => any;
    hasSetAccessPermission:boolean;
    isDraftDocLoading:boolean;
    getClientInfo:(clientId:string) => void;
    officeLocations: IDropdown[];
    updateOfficeLocation: (officeLocation: number) => void;
    selectedOfficeLocationId: number;
    uploadBlobYear: number;
    requestStorageAccountId: () => void;
    storageAccountId: number;
    signatureFlowSettings: ISignatureFlowSettings;
}
export interface UploadDocumentState {
    documentUploadData: DocumentUploadData[],
    sasContainer: SasContainer[],
    config: {
        dropzoneSelector: string,
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: boolean,
        postUrl: string
    },
    isFileUploadInProgress: boolean,
    taxYear: any,
    clientName: string,
    clientId: string,
    showValidationError:{
        clientName:boolean,
        taxYear: boolean,
        officeLocation: boolean,
        clientId:boolean
    },
    officeLocationId: number
}
export class UploadDocumentModal extends React.Component<UploadDocumentProps, UploadDocumentState>{
    constructor(props: UploadDocumentProps) {
        super(props);

        this.state = {
            documentUploadData: [],
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            isFileUploadInProgress: true,
            taxYear: getCurrentTaxYear(),
            clientName: "",
            clientId: "",
            showValidationError:{
                clientName:false,
                taxYear: false,
                officeLocation: false,
                clientId:false
            },
            officeLocationId: 0
        }

        this.handleExpiryDate = this.handleExpiryDate.bind(this);
    }
    
    componentDidMount(): void {
        if(this.props.onRef){
            this.props.onRef(this);
        }
    }

    private searchDelay = 500;

    private setNoContent() {
        if (this.props.isDraftDocLoading) {
            return (<LoadingOverlay style={{ height: '300px' }}>
                <Loader loading={this.props.isDraftDocLoading} />
            </LoadingOverlay>)
        } else {
            return SFDocument.emptyFileListMessage;
        }
    }
    private tableOptions = {
        noDataText: this.setNoContent()
    };
    
    UNSAFE_componentWillMount() {
        this.props.requestDocumentTypes();
        this.props.requestStorageAccountId();
        this.setState({
            documentUploadData: this.props.documentUploadData,
            taxYear: this.props.taxYear,
            clientName: this.props.clientName,
            clientId: this.props.clientId,
            officeLocationId:this.props.selectedOfficeLocationId

        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: UploadDocumentProps) {
        if (!isEqual(this.props.documentUploadData, nextProps.documentUploadData)) {
            this.setState({
                documentUploadData: nextProps.documentUploadData
            });
        }
        if (!isEqual(this.props.taxYear, nextProps.taxYear)) {
            this.setState({
                taxYear: nextProps.taxYear
            });
        }
        if (!isEqual(this.props.clientName, nextProps.clientName)) {
            this.setState({
                clientName: nextProps.clientName
            });
        }
        if (!isEqual(this.props.clientId, nextProps.clientId)) {
            this.setState({
                clientId: nextProps.clientId
            });
        }
        if (!isEqual(this.props.selectedOfficeLocationId, nextProps.selectedOfficeLocationId)) {
            this.setState({
                officeLocationId: nextProps.selectedOfficeLocationId
            });
        }
    }

    eventHandlers = {
        addedfiles: async(files: any) => {
            try {
                let _self = this;
                _self.validateFileCount(files);
                const fileList:IDocumentFileData[]=await _self.checkFileEncryption(files)
                let documentUploadData: DocumentUploadData[] = _self.convertToModel(fileList);
                documentUploadData = _self.handleDuplicates(documentUploadData);
                if (documentUploadData.length > 0) {
                    _self.validateFileContent(documentUploadData).then((result) => {
                        _self.setState({
                            documentUploadData: _self.state.documentUploadData.concat(result),
                            isFileUploadInProgress: true
                        }, () => {
                            let storageAccountId = this.props.storageAccountId;
                            let uploadedDocuments = this.props.documentUploadData.filter(x=>x.status == UploadStatus.Uploaded || x.status == UploadStatus.Converting)
                            if(uploadedDocuments.length>0){
                                storageAccountId = uploadedDocuments[0].storageAccountId;
                            }
                            this.props.fileUploadInProgress && this.props.fileUploadInProgress(this.state.isFileUploadInProgress);
                            _self.getUploadLink(storageAccountId);
                        });
                    });
                }
            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }

    public validationError = (fieldName: 'ClientId' |'ClientName' | 'TaxYear' | 'OfficeLocation', value:boolean) => {
        if(fieldName == 'ClientId'){
            this.setState({showValidationError:{...this.state.showValidationError, clientId:value}})
        }
        else if(fieldName == 'ClientName'){
            this.setState({showValidationError:{...this.state.showValidationError, clientName:value}})
        }
        else if(fieldName == 'TaxYear'){
            this.setState({showValidationError:{...this.state.showValidationError, taxYear:value}})
        }
        else if(fieldName == 'OfficeLocation'){
            this.setState({showValidationError:{...this.state.showValidationError, officeLocation:value}})
        }
    }

    public removeAllValidationErrors = () => {
        this.setState({showValidationError:{clientName:false, taxYear:false, officeLocation:false, clientId:false}});
    }

    private checkFileEncryption =async(files:any ):Promise<IDocumentFileData[]>=>{
        let fileList:IDocumentFileData[]=[];
        for (let i = 0; i <files.length; i++) {
            let result:any = await new Promise((resolve)=>{
                var reader = new FileReader();   
                reader.readAsText(files[i]) 
                reader.onload = (event:any)=>resolve(event.target.result)
            })
            fileList.push(files[i])
            if(result.indexOf('/Encrypt') !== -1 || result.indexOf('<encryption') !== -1){
               fileList[i].encrypted=true
            }
            else{
                fileList[i].encrypted=false
            }                                   
        }
        return fileList
    }

    private convertToModel = (uploadedFiles: any): DocumentUploadData[] => {
        let documentUploadData: DocumentUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            let uploadedFile = uploadedFiles[i];
                    if (validateFileSize(uploadedFile)) {
                        let tmpFileName = uploadedFiles[i].name;
                        let fileExtension = getFileExtension(tmpFileName);
                        
                        // Validate file extension
                        if (validateSFUploadFileType(fileExtension)) {
                           if(!validateEncryption(uploadedFile)){
                               documentUploadData.push({
                                   name: tmpFileName,
                                   number: (documentUploadData.length + this.state.documentUploadData.length) + 1,
                                   progressBar: 0,
                                   size: getFileSize(uploadedFile.size),
                                   status: UploadStatus.Wait,
                                   sasGuid: "",
                                   gridRowDisable: true,
                                   rowIndex: (documentUploadData.length + this.state.documentUploadData.length) + 1,
                                   file: uploadedFile,
                                   documentType: 0,
                                   expireon: undefined, //  need to change
                                   sourceFileGuid: null,
                                   storageAccountId: uploadedFile.storageAccountId
                                });
                            }
                        }
                    }
        }
        return documentUploadData;
    }
    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.documentUploadData.length > this.props.maxFileLimitUpload) {
            throw new Error("You cannot upload more than " + this.props.maxFileLimitUpload + " files.");
        }
    }
    private handleDuplicates = (sfUploadData: DocumentUploadData[]): DocumentUploadData[] => {
        for (let i = 0; i < sfUploadData.length; i++) {
            let file = sfUploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);
            let filecount = 1;
            while (isFileExist(fileName, this.state.documentUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, '');
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            sfUploadData[i].name = fileName;
        }
        return sfUploadData;
    }
    private validateFileContent = (uploadData: DocumentUploadData[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: DocumentUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!validateWordAndPDFFileContent(result)) {
                        let index = uploadData.findIndex(x => x.name == _tmpTaxReturnUploadData[i].name)
                        uploadData.splice(index, 1)
                        VenusNotifier.Warning(SFDocument.sfUploadUnsupportedFileWarning, null);
                    }
                    resolve(result)
                });
            });
        }
        return promise.then(() => { return uploadData })
    }
    private getUploadLink = (storageAccountId: number) => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.documentUploadData.filter(x => x.status == UploadStatus.Wait).forEach((file: DocumentUploadData, index: number) => {
            file.status = UploadStatus.Initiating;
            this.props.getUploadLink('api/SignatureFlow/Document/GetSFDocumentsUploadLinkAsync', storageAccountId, (data?: SFUploadStore.IBlobFile) => {
                try {
                    if (data) {
                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;
                        file.storageAccountId = data.storageAccountId;
                        _self.setState({ documentUploadData: _self.state.documentUploadData },
                            () => uploadHelperFunctions.uploadFile(file.file, data, file.name ? file.name : "",
                                _self.uploadProgressCallback, _self.uploadCommittCallBack));
                    } else {
                        throw new Error("Upload link not found !!");
                    }
                } catch (error) {
                    _self.state.documentUploadData.splice(index, 1);
                    _self.setState({ documentUploadData: _self.state.documentUploadData });
                }
            });
        });
    }
    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempGridData = this.state.documentUploadData;
        tempGridData.map((tempGridDataValue: any, index: number) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            documentUploadData: tempGridData
        });
    }
    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.documentUploadData.length > 0) {
            let tempGridData = this.state.documentUploadData;
            tempGridData.map((tempGridDataValue: any, index: number) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });

            let uploadedFile = fileToUpload.file;
            let magicNumber: string = "";
            new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    magicNumber = result;
                    if (isWordFile(result)) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                });
            }).then(result => {
                if (result) {
                    let newDocumentGuid = Guid.newGuid().toString();
                    this.updateSasGuidAndInProgress(fileToUpload.fileGUID, newDocumentGuid);
                    this.props.SendDocumentToExtractSignatureControlsAsync(newDocumentGuid, fileToUpload.fileGUID, this.props.uploadBlobYear, tempGridData[0].storageAccountId, () => {
                        this.addDocumentData(newDocumentGuid, fileToUpload.fileName, "",
                            fileToUpload.fileGUID, FileType.word, tempGridData[0].storageAccountId);
                    });
                }
                else {
                    this.addDocumentData(fileToUpload.fileGUID, fileToUpload.fileName, fileToUpload.sasUrl, null, this.fileType(magicNumber), tempGridData[0].storageAccountId);
                }

                this.setState({
                    documentUploadData: tempGridData
                }, () => {
                    if (!this.isUploadOnGoing()) {

                        this.props.updateDocumentUploadData(this.state.documentUploadData);
                        this.setState({ isFileUploadInProgress: false }, () => {
                            this.props.fileUploadInProgress && this.props.fileUploadInProgress(this.state.isFileUploadInProgress);
                        });
                    }
                });

            });
        }
    }

    fileType = (signature: any): FileType => {
        let type: FileType = FileType.undefined;
        switch (signature) {
            case '25504446': type = FileType.pdf;
                break;
            case 'D0CF11E0':
            case '504B34': type = FileType.word;
                break;
            case 'EFBBBF22':
            case '5369676E':
            case '53656E64': type = FileType.csv;
        }
        return type;
    }

    updateSasGuidAndInProgress = (documentGuid: string, newDocumentGuid: string) => {
        let tempUploadData = this.state.documentUploadData;
        for (var i = 0; i < tempUploadData.length; i++) {
            if (tempUploadData[i].sasGuid === documentGuid) {
                tempUploadData[i].sasGuid = newDocumentGuid;
                tempUploadData[i].sourceFileGuid = documentGuid;
                this.setState({
                    documentUploadData: tempUploadData
                });
                break;
            }
        }
    }

    private addDocumentData = (documentGuid: string, fileName: string, sasUrl: string,
        sourceFileGuid: string | null, fileType: FileType, storageAccountId: number) => {
        var documentData: IDocumentData = {} as IDocumentData;
        documentData.documentGuid = documentGuid;
        documentData.fileName = fileName;
        documentData.sasUrl = sasUrl;
        documentData.isPDFloaded = false;
        documentData.sourceFileGuid = sourceFileGuid;
        documentData.fileType = fileType;
        documentData.storageAccountId = storageAccountId;
        this.props.addDocumentData(documentData);
    }

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.documentUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>
    }
    progressbar(cell: any, row: any) {
        return <ProgressBar
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }
    selectFormat(cell: any, row: any) {
        return <div id="ddlUploadSFDocument" className='drpoDown-custom-scroll-bar'>
            <DropdownComponent
                id="ddlUploadSFDocument"
                options={this.setDocumentTypes(this.props.documentTypes)}
                selectedValue={cell}
                onChange={this.handleDocumentType.bind(this, row)}
                customPlaceHolder="Document Type"
                disabled={row.gridRowDisable}
                searchable={true}
                arrowRenderer={() => <DropdownArrow/>}
                clearRenderer={() => <XLg color='#212529'/>}
            />
        </div>
    }
    dateFormat(cell: any, row: any) {
        let date = new Date();
        date.setMonth(date.getMonth() + 1);
        return <CustomDatePicker
            placeholder='Select Date'
            value={cell}
            onChange={(value: any) => { this.handleExpiryDate(row, value) }}
            disabled={row.gridRowDisable}
            minDate={date}
            showClearButton={false}
            customOpenIcon={<CalendarCheck/>}
        />
    }
    buttonFunction(cell: any, row: any) {
        return (
            <Button className='delete-action-button' 
            onClick={() => this.deleteDocument(row)} disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false}
            variant='' size='sm' type='reset' data-test-auto="C166BDDC-ADE5-46F2-8B94-7ABAFFC4BED7" style={{color: '#05386B' , cursor:'pointer'}}>Delete</Button>
        );
    }
    fileNameFormat(cell: any, row: any){
        return <div title={cell} className="ellipsis"><PaperClip/>  {cell}</div>
    }
    handleDocumentType = (row: any, value: any) => {
        var _gridData = [...this.state.documentUploadData];

        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.documentType = (value == null || value == undefined) ? 0 : value;
                gridData.expireon = this.setDocumentExpiryDate(value);
            }
        });
        this.setState({ documentUploadData: _gridData }, () => {
            this.props.updateDocumentUploadData(this.state.documentUploadData);
        });
    }
    setDocumentTypes = (documentTypes: IDocumentType[]) => {
        var filterTypes: IDocumentType[] = documentTypes;
        if (documentTypes.length > 0) {
            filterTypes = documentTypes.filter(x => x.isActive);
        }
        var documentTypesArray: any = [];
        filterTypes && isArray(filterTypes) && filterTypes.map((documentType: IDocumentType) =>
            documentTypesArray.push({
                value: documentType.id,
                label: documentType.description
            }));

        return documentTypesArray;
    }
    handleExpiryDate = (row: any, value: any) => {
        var _gridData = [...this.state.documentUploadData];
        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.expireon = value ? value : undefined;
            }
        });

        this.setState({ documentUploadData: _gridData }, () => {
            this.props.updateDocumentUploadData(this.state.documentUploadData);
        });
    }
    setDocumentExpiryDate = (documentType: number) => {
        if (documentType == null || documentType == undefined) {
            return undefined;
        }
        var temp = this.props.documentTypes.filter((item, index) => {
            return item.id == documentType;
        });

        var result = (temp[0].expiration != null || temp[0].expiration != undefined) ?
            moment(new Date()).add(temp[0].expiration, 'months') : moment(new Date());
        return result.toDate();
    }
    deleteDocument = (row: any) => {
        let _self = this;
        if (row.inProcess) {
            VenusNotifier.Warning(SFDocument.BackgroundProcessInProgress, null);
            return;
        }
        bootbox.confirm({
            title:'Delete Document',
            message: SFDocument.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-white btn-default sf-upload-delete-confirm'
                },
                confirm: {
                    label: 'OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.props.deleteUploadedSFDocument(row.sasGuid, row.storageAccountId, () => {

                        if (row.sourceFileGuid) {
                            this.props.deleteUploadedSFDocument(row.sourceFileGuid, row.storageAccountId);
                        }

                        this.props.deleteDocumentData(row.sasGuid);
                        var _gridData = [..._self.state.documentUploadData];
                        _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);

                        //Update row index
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].number = i + 1;
                        }
                        let _tempgridData = _gridData;

                        let _uploadData = [..._self.state.sasContainer];
                        _uploadData = _uploadData.filter(i => i.sasGuid != row.sasGuid);

                        let _tempUploadData = _uploadData;
                        if (_gridData.length == 0) {
                            _self.setState({ isFileUploadInProgress: true }, () => {
                                this.props.fileUploadInProgress && this.props.fileUploadInProgress(this.state.isFileUploadInProgress);
                            });
                        }
                        _self.setState({
                            documentUploadData: _tempgridData,
                            sasContainer: _tempUploadData,
                        }, () => {
                            this.props.updateDocumentUploadData(this.state.documentUploadData);
                            _self.forceUpdate();
                        });
                    });
                }
            }
        }).find(".modal-dialog")
        .addClass("modal-dialog-centered");
    }

    onTaxYearChange = (val: any) => {
        if(this.state.showValidationError.taxYear && val != null){
            this.setState({ taxYear: val , showValidationError:{...this.state.showValidationError, taxYear : false} }, () => {
                this.props.updateTaxYear(this.state.taxYear);
            });    
        }
        else{
            this.setState({ taxYear: val }, () => {
                this.props.updateTaxYear(this.state.taxYear);
            });
        }
        
    }
    onOfficeLocatioChange = (val: any) => {
        if(this.state.showValidationError.officeLocation){
            this.setState({ officeLocationId: val, showValidationError:{...this.state.showValidationError, officeLocation : false}  }, () => {
                    this.props.updateOfficeLocation(this.state.officeLocationId);
                });
        }
        else {
            this.setState({ officeLocationId: val }, () => {
                this.props.updateOfficeLocation(this.state.officeLocationId);
            });
        }
    }


    onClientNameChange(e: any) {
        if(this.state.showValidationError.clientName && e.target.value.length > 0){
            this.setState({ clientName: e.target.value,showValidationError:{...this.state.showValidationError, clientName : false} }, () => {
                this.props.updateClientName(this.state.clientName);
            });
        }

        else{
            this.setState({ clientName: e.target.value }, () => {
                this.props.updateClientName(this.state.clientName);
            });
        }

    }
    
    onClientIdChange(e: any) {
        if(this.state.showValidationError.clientId && e.target.value.length > 0){
            this.setState({ clientId: e.target.value,showValidationError:{...this.state.showValidationError, clientId : false} }, () => {
                this.onUpdateClientId();
            });
        }
        else{
            this.setState({ clientId: e.target.value }, () => {
                this.onUpdateClientId();
            });
        }
    }

    onUpdateClientId = debounce(() => {
        this.props.updateClientId(this.state.clientId);
        if(trim(this.state.clientId).length > 0){
            this.props.getClientInfo(this.state.clientId);
        }
    }, this.searchDelay);

    public render() {
        const columns = [
            {
                header: 'File Name',
                key: 'name',
                isKey: true,
                dataFormat: this.fileNameFormat,
                width: '20%',
                columnClassName: 'word-Visible text-left-align fontSize16 fontWeight700'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbar,
                width: '20%',
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultType,
                width: '7%',
                hidden: false,
                columnClassName: 'fontSize16 fontWeight400'
            },
            {
                header: 'Document Type',
                key: 'documentType',
                isKey: false,
                dataFormat: this.selectFormat.bind(this),
                columnClassName: 'overflowVisible text-left-align fontSize14',
                width: '15%',
            },
            {
                header: 'Expires On',
                key: 'expireon',
                isKey: false,
                dataFormat: this.dateFormat.bind(this),
                columnClassName: 'overflowVisible fontSize14 padL20',
                width: '15%',
                className: 'padL20',
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: '10%',
                columnClassName: 'fontSize16 padL20',
                className: 'padL20',
            }
        ];
        let isUploaded = this.state.documentUploadData.length > 0;
        return (<div className="upload-doc-modal" style={{ width: "100%", marginLeft: "0" }}>
            <Col sm={2} className='upload-drop-zone'>
                <DocumentUploadDropzoneComponent
                    componentConfig={this.state.config}
                    djsConfig={djsConfig}
                    eventHandlers={this.eventHandlers}
                    autoTestId={"5A961DFE-5411-4DC9-9D51-DAA2040FF38E"}
                />
            </Col>
            <Col sm={10}  style={{float: "right"}}>
                <Row className='marB35 upload-doc-header-action' style={{justifyContent: 'flex-end',alignItems: 'center'}}>
                    <Col md={3} className='padT15'>
                        <Button
                            variant='default'
                            className='btn btn-white upload-set-access-btn'
                            disabled={!this.props.hasSetAccessPermission || !isUploaded}
                            title={this.props.hasSetAccessPermission ?  "" : RBACInfo.NoPermissionTitle}
                            onClick={() => this.props.onDocumentAccessOpen()}
                            >
                            <SetAccessHeaderIcon /><span>Set Access</span>
                        </Button>
                    </Col>
                    <hr/>
                    <Col md={2} sm={2}>
                        <div className=" dis-inline txt-al fontWeight600" style={{textAlign:'left'}}>Client ID</div>
                        {this.props.signatureFlowSettings.clientIdSettings.mandateClientId && <span className="fas fa-asterisk compulsory-star"></span>}
                        <FormControl type="text"
                            value={this.state.clientId}
                            title={this.state.clientId}
                            className={this.state.showValidationError.clientId && 'show-validation-field'}
                            placeholder='Client ID'
                            onChange={(e: React.ChangeEvent) => this.onClientIdChange(e)}
                            disabled={!isUploaded} />
                        {this.state.showValidationError.clientId && <span className='show-validation-text'>This field is required.</span>}
                    </Col> 
                    <Col md={2} sm={2}>
                        <div className=" dis-inline txt-al fontWeight600">Client Name</div>
                        <span className="fas fa-asterisk compulsory-star"></span>
                        <FormControl type="text"
                            value={this.state.clientName}
                            title={this.state.clientName}
                            className={this.state.showValidationError.clientName && 'show-validation-field'}
                            onChange={(e: React.ChangeEvent) => this.onClientNameChange(e)}
                            disabled={!isUploaded}
                            placeholder='Client Name' />
                        {this.state.showValidationError.clientName && <span className='show-validation-text'>This field is required.</span>}
                    </Col>
                    <Col md={2} sm={2} className='uploadOfficeLocationDropdown drpoDown-custom-scroll-bar'>
                        <div className="dis-inline txt-al fontWeight600">Office Location</div>
                        <span className="fas fa-asterisk compulsory-star"></span>
                        <DropdownComponent
                            id="uploadOfficeLocationDropdown"
                            className={this.state.showValidationError.officeLocation && 'show-validation-field'}
                            options={Helper.prepareDropDownData(this.props.officeLocations)}
                            onChange={this.onOfficeLocatioChange}
                            selectedValue={this.state.officeLocationId}
                            arrowRenderer={()=><DropdownArrow/>}
                            searchable={false}
                            disabled={!isUploaded}
                            customPlaceHolder='Office Location'
                            data-test-auto={"a3de1541-3a45-43be-a834-cb8720e799ca"}
                            clearRenderer={()=><XLg color='#212529'/>}
                            />
                            {this.state.showValidationError.officeLocation && <span className='show-validation-text'>This field is required.</span>}
                    </Col>
                    <Col md={2} sm={2}>
                        <div className="dis-inline txt-al fontWeight600">Tax Year</div>
                        <span className="fas fa-asterisk compulsory-star"></span>
                        <DropdownComponent
                            id="ddlTaxYearSign"
                            options={Helper.GetTaxYearForUploadingDocument()}
                            onChange={this.onTaxYearChange}
                            selectedValue={this.state.taxYear}
                            className={this.state.showValidationError.taxYear && 'show-validation-field'}
                            arrowRenderer={()=><DropdownArrow/>}
                            searchable={false}
                            disabled={!isUploaded}
                            customPlaceHolder='Tax Year'
                            clearRenderer={()=><XLg color='#212529'/>}
                            />
                        {this.state.showValidationError.taxYear && <span className='show-validation-text'>This field is required.</span>}
                        
                    </Col>
                </Row>
                <Row className="marL10">

                    <div id="sfUploadDocumentTable" className="padT07">
                        {
                            <BootstrapTable data={this.state.documentUploadData}
                                bodyContainerClass={'overflowVisible'}
                                tableHeaderClass='file-upload-table-header'
                                trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                                bordered={false} options={this.tableOptions}>
                                {columns.map((value, index) => {
                                    return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key}
                                        dataFormat={value.dataFormat} width={value.width}
                                        className={value.className ? value.className : ''}
                                        columnClassName={value.columnClassName}                                                                
                                        dataAlign={"left"}>
                                        <span title={value.header} className='fontWeight400'>{value.header}</span>
                                    </TableHeaderColumn>;
                                })}
                            </BootstrapTable>
                        }
                    </div>
                </Row>
            </Col>
        </div>)
    }
}
