import * as React from 'react';
import { Row } from 'react-bootstrap';
import { ISignatureFlowSettings, DelegationType } from '../../../models/SignatureFlowSettings';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { SignatureFlowDelegationPopUp } from '../SignatureFlowDelegationPopUp';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { SettingIcon } from '../../svg/CustomSvgIcons';
import { typeOfSettings } from '../../helper/Constants';

export interface ISignatureFlowDelegationProps {
    resourceId: string;
    signatureFlowSettings: ISignatureFlowSettings
    userList: IUserModel[];
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings, settingsType: typeOfSettings) => void;
}

export interface ISignatureFlowDelegationState {
    showPopup: boolean;
    SenderDelegation: string;
    DelegateToEveryone: string;
}

export class SignatureFlowDelegation extends React.Component<ISignatureFlowDelegationProps, ISignatureFlowDelegationState> {
    state = {
        showPopup: false,
        SenderDelegation : "SenderDelegation",
        DelegateToEveryone : "DelegateToEveryone"
    };

    handleSenderdelegation = (event: any) => {
        const signatureFlowSettings = this.props.signatureFlowSettings;
        if (signatureFlowSettings) {
            signatureFlowSettings.delegationSettings.delegationType = DelegationType.SenderDelegation;
            this.props.updateCompanySettings(signatureFlowSettings, typeOfSettings.Delegation);
        }
    };

    handleAutomaticallyDelegateToEveryone = (event: any) => {
        const signatureFlowSettings = this.props.signatureFlowSettings;
        if (signatureFlowSettings) {
            signatureFlowSettings.delegationSettings.delegationType = DelegationType.DelegateToEveryone;
            this.props.updateCompanySettings(signatureFlowSettings, typeOfSettings.Delegation);
        }
    };

    handleDelegationSettingsClick = (event: any) => {
        if (this.props.signatureFlowSettings.delegationSettings.delegationType == DelegationType.SenderDelegation) {
            this.setState({
                showPopup: true
            });
        }
    }

    onPopupHide = () => {
        this.setState({
            showPopup: false
        })
    }

    updateDelgatedUsers = (userToDelegate: number[]) => {
        const signatureFlowSettings = this.props.signatureFlowSettings;
        if (signatureFlowSettings) {
            signatureFlowSettings.delegationSettings.usersAllowedToDelegate = userToDelegate;
            this.props.updateCompanySettings(signatureFlowSettings, typeOfSettings.Delegation);
        }
    };

    public render() {
        const delegationEnabled = this.props.signatureFlowSettings.delegationSettings.delegationType == DelegationType.SenderDelegation || false;
        return <div className="new-main-content Senderdelegation" data-resource-id={this.props.resourceId}>
            <h4 className='sigflow-settings-heading'>Delegation</h4>
            <div>
                <RadioButtonComponent
                    id={"Senderdelegation"}
                    text={"Sender delegation"}
                    style={{'marginRight':'11px'}}
                    checked={this.props.signatureFlowSettings.delegationSettings.delegationType === this.state.SenderDelegation}
                    onChange={this.handleSenderdelegation}
                    value={this.state.SenderDelegation}
                    dataTestAuto={"A2B08E52-F4AC-4B45-9A9F-4681C4B05227"}
                />
                <span style={{cursor:'pointer'}} onClick={this.handleDelegationSettingsClick}>
                    <SettingIcon fill='#0973BA' marginBottom={'3px'}/>
                </span>
            </div>
            <div className='marT7-i'>
                <RadioButtonComponent
                    id={"Automaticallydelegatetoeveryone"}
                    text={"Automatically delegate to everyone"}
                    checked={this.props.signatureFlowSettings.delegationSettings.delegationType === this.state.DelegateToEveryone}
                    onChange={this.handleAutomaticallyDelegateToEveryone}
                    value={this.state.DelegateToEveryone}
                    dataTestAuto={"B668FADF-6956-4DAD-9E09-0E964C320B3F"}
                />
            </div>

            <SignatureFlowDelegationPopUp
                usersAllowedToDelegate={this.props.signatureFlowSettings.delegationSettings.usersAllowedToDelegate || []}
                userList={this.props.userList}
                updateDelgatedUsers={this.updateDelgatedUsers}
                showState={this.state.showPopup}
                onHide={this.onPopupHide}
            />

        </div>;
    }
}

export default SignatureFlowDelegation;