import * as React from 'react';
import Select from 'react-select';
import { Col, Row, FormControl } from 'react-bootstrap';
import { IUserProfile } from '../components/navigation/profile/ProfileObjects';
import { ISignatureFlowSettings, DelegationType, MessageType } from '../models/SignatureFlowSettings';
import { ISigningInfo, SenderSignOption, initialSigningInfo, IContact, IClient, createClient, ClientType, initialClient, AuthenticationType, SigningType } from '../models/SigningInfo';
import { IMessage, initialMessage } from '../models/SignatureFlowSettings';
import { SFStep2Constants } from './helper/Constants';
import * as SignatureFlowSettingsStore from '../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { ICompany } from '../../Core/ViewModels/Company/CompanyViewModel';
import { Guid } from '../../Core/Utilities/Guid';
import * as _ from 'lodash';
import { ISendForSignatureData } from '../store/SFStore';
import { ClientAuthenticationList, ClientDetailsMode } from './settings/ClientAuthenticationList';
import { DropdownArrow, PlusCircle, XLg } from './svg/CustomSvgIcons';
import { ZERO_NUMBER, getHighestSOUptoLastCC } from './helper/HelperFunctions';


interface SFStep2Props {
    company: ICompany;
    profile: IUserProfile;
    updateSFData: (signingInfo: ISigningInfo)=> void;
    requestSavedMessages: () => void;
    requestCompanySignatureSettings: () => void;
    companySettings: ISignatureFlowSettings;
    SavedMessages: SignatureFlowSettingsStore.ISavedMessageDictionary;
    signingInfo: ISigningInfo;
    userDelegations: number[];
    users: IUserModel[];
    contacts: IContact[];
    questions: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionDictionary,
    clientAuthenticationData: ISendForSignatureData,
    getAuthenticationParameter: (email: string, authenticationType: AuthenticationType, callback: () => void, question?: string) => void;
    isRecipientsUpdated: (isUpdated: boolean) => void;
    
}

interface SFStep2State {
    signingInfo: ISigningInfo;
    selectedMsg: IMessage | undefined;
    isMsgEdit: boolean;
    contacts: IContact[];
    showQuestionAnswerPopup: boolean
    selectedRecipient: IClient;
}



export class SFStep2 extends React.Component<SFStep2Props, SFStep2State>{


    private recipientInpRef: any;
    private messageTextAreaRef: any;
    constructor(props: SFStep2Props) {
        super(props);
        this.state = {
            signingInfo: initialSigningInfo,
            selectedMsg: undefined,
            isMsgEdit: false,
            contacts: [],
            showQuestionAnswerPopup: false,
            selectedRecipient: initialClient
        };
    }

    componentDidMount() {
        let signingInfo = this.props.signingInfo;
        let contacts: IContact[] = [];
        if (!signingInfo.sender.userId) {
            signingInfo.sender = {} as IUserModel;
            signingInfo.sender.userId = 0;
            signingInfo.sender.firstName = this.props.company.companyInfo.companyName;
            signingInfo.sender.lastName = '';
            let sender = this.props.users.find((user, index) => (user.userId === this.props.profile.userId));
            if (sender) {
                signingInfo.sender.email = sender.email;
                signingInfo.delegate = sender;
                signingInfo.clientSender = createClient(sender.email, Guid.newGuid(), ClientType.Sender, AuthenticationType.None, 0, '');
            }
        }
        if(signingInfo.messageId != undefined || signingInfo.messageId != null){
            const constructMessage:IMessage = {
                id: !signingInfo.messageId ? 0 : signingInfo.messageId,
                name: '',
                body: signingInfo.message,
                messageType: MessageType.Save,
                isEditable: false,
                subject: signingInfo.title
            }

            this.setState({selectedMsg:constructMessage, 
                isMsgEdit: (signingInfo.messageId && signingInfo.messageId != 0) ? this.isMessageEditable(this.props.signingInfo.messageId) : true});
        }
        else if(!signingInfo.messageId)
        {
            let savedMessageIndex = parseInt(Object.keys(this.props.SavedMessages)[0]);
            if (this.props.SavedMessages[this.props.companySettings.messageSettings.welcomeMessage]) {
                savedMessageIndex = this.props.companySettings.messageSettings.welcomeMessage;
            }
            signingInfo.message = this.props.SavedMessages[savedMessageIndex].messageDetails.body;
            signingInfo.title = this.props.SavedMessages[savedMessageIndex].messageDetails.subject;
            signingInfo.messageId = this.props.SavedMessages[savedMessageIndex].messageDetails.id;
            this.setState({
                selectedMsg: this.props.SavedMessages[savedMessageIndex].messageDetails,
                isMsgEdit: this.props.SavedMessages[savedMessageIndex].messageDetails.isEditable
            });
        }
        else if (!this.state.selectedMsg) {
            if (this.props.signingInfo.message && this.props.signingInfo.messageId !== undefined) {

                (!this.props.signingInfo.messageId || this.props.signingInfo.messageId === 0) ?
                this.setState({ selectedMsg: initialMessage, isMsgEdit: true }) :
                this.setState({ selectedMsg: this.props.SavedMessages[this.props.signingInfo.messageId].messageDetails,
                                isMsgEdit: this.props.SavedMessages[this.props.signingInfo.messageId].messageDetails.isEditable });            
            }
        }
       
        if (this.props.contacts) {
            contacts = this.props.contacts;
        }
        if (signingInfo.recipients.length !== 0 && !this.props.companySettings.securitySettings.enableSignerAuthentication) {
            signingInfo.recipients.map((r, i) => {
                return this.clearAuthentication(r);
            })
        }
        this.setState({ signingInfo: signingInfo, contacts: contacts }, () => { this.props.isRecipientsUpdated(false);});
    }

    isMessageEditable = (messageId: number) => {
        if(!this.props.SavedMessages[messageId]){
            return true;                                          
        }
        return this.props.SavedMessages[messageId].messageDetails.isEditable
    }

    clearAuthentication = (recipient: IClient) => {
        recipient.authenticationType = AuthenticationType.None;
        recipient.countryCode = '';
        recipient.mobileNumber = '';
        recipient.first4SSN = '';
        recipient.last4SSN = '';
        recipient.question = '';
        recipient.answer = '';
        return recipient;
    }

    getDelegateUsers = (): any => {
        let senderOptions = [];

        senderOptions.push({ value: 0, label: this.props.company.companyInfo.companyName });

        if (this.props.profile) {
            senderOptions.push({
                value: this.props.profile.userId,
                label: this.props.profile.firstName + ' ' + this.props.profile.lastName
            });
        }

        if (this.props.companySettings.delegationSettings.delegationType === DelegationType.SenderDelegation && this.props.userDelegations) {
            senderOptions = senderOptions.concat(this.props.users
                .filter(user => this.props.companySettings.delegationSettings.usersAllowedToDelegate.includes(user.userId) &&
                    user.userId !== this.props.profile.userId)
                .map(allowedUser => {
                    const delegateUser = this.props.users.find(u => (u.userId === allowedUser.userId));
                    return {
                        value: allowedUser.userId,
                        label: delegateUser!.firstName + ' ' + delegateUser!.lastName
                    }
                }));
        }

        if (this.props.companySettings.delegationSettings.delegationType === DelegationType.DelegateToEveryone) {
            const users = _.cloneDeep(this.props.users)
                .filter(user => user.userId != this.props.profile.userId)
                .sort(function (a, b) {
                    var nameA = a.firstName.toLowerCase(), nameB = b.firstName.toLowerCase();
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0; //default return value (no sorting)
                });
            senderOptions = senderOptions.concat(users.map(user => {
                return {
                    value: user.userId,
                    label: user.firstName + ' ' + user.lastName
                }
            }));
        }

        return senderOptions;
    }

    getSenderSignOptions = () => {
        if (this.state.signingInfo.sender.userId == 0) {
            return [{ value: SenderSignOption.NO_SIGN, label: SFStep2Constants.GetSenderSignOptionDisplayText(SenderSignOption.NO_SIGN) }];
        }
        else {
            return [{ value: SenderSignOption.NO_SIGN, label: SFStep2Constants.GetSenderSignOptionDisplayText(SenderSignOption.NO_SIGN) },
            { value: SenderSignOption.SIGNS_FIRST, label: SFStep2Constants.GetSenderSignOptionDisplayText(SenderSignOption.SIGNS_FIRST) },
            { value: SenderSignOption.SIGNS_LAST, label: SFStep2Constants.GetSenderSignOptionDisplayText(SenderSignOption.SIGNS_LAST) }];
        }
    }

    populateMessagesDD = (): { value: string, label: string }[] => {
        let options: { value: string, label: string }[] = [];
        options.push({ value: "0", label: "Custom Message" });
        for (let key in this.props.SavedMessages) {
            options.push({ value: key, label: this.props.SavedMessages[key].messageDetails.name });
        }
        return options;
    }


    populateEmailOptions = (): { value: string, label: string }[] => {
        let options: { value: string, label: string }[] = [];
        for (let key in this.state.contacts) {
              options.push({ value: key, label: this.props.contacts[key].emailAddress });
        }
        return options;
    }

    handleSenderChange = (sender: any) => {
        if (sender != null || sender != undefined) {
            if (sender.value == 0) {
                this.props.isRecipientsUpdated(true);
                const delegate = this.props.users
                    .find((user, index) => (user.userId === this.props.profile.userId));
                if (delegate) {
                    let user = {} as IUserModel;
                    user.userId = 0;
                    user.email = delegate.email;
                    user.firstName = sender.label;
                    this.state.signingInfo.sender = user;
                    this.state.signingInfo.clientSender.emailAddress = delegate.email;
                    this.state.signingInfo.delegate = delegate;
                    this.state.signingInfo.senderSignOption = SenderSignOption.NO_SIGN;
                    this.setState({ signingInfo: this.state.signingInfo });
                }
            }
            else {
                this.state.signingInfo.sender = this.props.users
                    .find((user, index) => (user.userId === sender.value))!;
                this.state.signingInfo.clientSender.emailAddress = this.state.signingInfo.sender.email;
                this.state.signingInfo.delegate = sender.value !== this.props.profile.userId
                    ? this.props.users.find((user, index) => (user.userId === this.props.profile.userId))
                    : undefined;
                this.setState({ signingInfo: this.state.signingInfo });
            }
        }
    }

    handleSenderSignOption = (signOption: any) => {
        if (this.state.signingInfo.senderSignOption == SenderSignOption.NO_SIGN &&
            signOption.value != SenderSignOption.NO_SIGN) {
            this.props.isRecipientsUpdated(true);
        }
        else if (signOption.value == SenderSignOption.NO_SIGN &&
            (this.state.signingInfo.senderSignOption == SenderSignOption.SIGNS_FIRST || this.state.signingInfo.senderSignOption == SenderSignOption.SIGNS_LAST)) {
            this.props.isRecipientsUpdated(true);
        }

        this.state.signingInfo.senderSignOption = signOption.value;
        this.setState({ signingInfo: this.state.signingInfo });
    }

    handleMessageDDChange = (msgOption: any) => {
        if (msgOption == undefined || msgOption == null || msgOption.value === "0") {
            this.state.signingInfo.message = '';
            this.state.signingInfo.title = '';
            this.state.signingInfo.messageId=0
            this.setState({ signingInfo: this.state.signingInfo, isMsgEdit: true, selectedMsg: initialMessage });
        }
        else {
            this.state.signingInfo.message = (msgOption == undefined || msgOption == null) ?
                initialMessage.body : this.props.SavedMessages[msgOption.value].messageDetails.body;
            this.state.signingInfo.title = (msgOption == undefined || msgOption == null) ?
                initialMessage.subject : this.props.SavedMessages[msgOption.value].messageDetails.subject == undefined || null ? "" :
                    this.props.SavedMessages[msgOption.value].messageDetails.subject;
            this.state.signingInfo.messageId = (msgOption == undefined || msgOption == null) ?
                initialMessage.id : this.props.SavedMessages[msgOption.value].messageDetails.id;

            this.setState({
                signingInfo: this.state.signingInfo,
                selectedMsg: (msgOption == undefined || msgOption == null) ? initialMessage : this.props.SavedMessages[msgOption.value].messageDetails,
                isMsgEdit: (msgOption == undefined || msgOption == null) ? false : this.props.SavedMessages[msgOption.value].messageDetails.isEditable
            });
            this.props.updateSFData(this.state.signingInfo);
        }
    }

    handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.state.signingInfo.message = event.currentTarget.value;
        this.setState({ signingInfo: this.state.signingInfo });
        this.props.updateSFData(this.state.signingInfo);
    }
    
    handleSubjectChange = (event: any) => {
        this.state.signingInfo.title = event;
        this.setState({ signingInfo: this.state.signingInfo });
        this.props.updateSFData(this.state.signingInfo);
    }

    handleSigningTypeChange = (signingType: any) => {
        let recipents: IClient[] = [];
        if(signingType  === SigningType.Custom){
            let ccOrder = -1;
            let ccCount = 0;
            recipents = this.state.signingInfo.recipients.map((r, i) => {
                if (r.clientType == ClientType.CC) {
                    if (ccOrder == -1) {
                        ccOrder = i + 1;
                    }
                    ccCount += 1;
                    r.signingOrder = ccOrder;
                }
                else {
                    r.signingOrder = i + 1 - (ccOrder == -1 ? 0 : ccCount-1);
                }
                return r;
            });
        }
        else if(signingType === SigningType.Parallel){
            let isCCInList = false;
            recipents = this.state.signingInfo.recipients.map((r, i) => {
                // recipients before CC = 1, CC recipients = 2, Empty recipients = 3 
                r.signingOrder = r.clientType == ClientType.CC ? 2 : isCCInList ? 3 : 1;
                isCCInList = r.clientType == ClientType.CC ? true : isCCInList;
                return r;
            });
        }
        this.state.signingInfo.signingType = signingType;
        this.setState({ signingInfo: this.state.signingInfo });
        this.props.updateSFData(this.state.signingInfo);
    }



    updateRecipient = (recipient: IClient, clientTypeUpdated?:boolean, signingOrderUpdate? : boolean) => {
        let signingInfo = this.state.signingInfo;
        let index = signingInfo.recipients.findIndex(m => m.clientGuid == recipient.clientGuid);
        signingInfo.recipients[index] = recipient;
        if(recipient.signingOrder && signingOrderUpdate){
            this.updateCCSigningOrder(signingInfo.recipients);
        }
        this.setState({ signingInfo: signingInfo }, () => {
            this.props.updateSFData(this.state.signingInfo);
            if(clientTypeUpdated)
                this.props.isRecipientsUpdated(true);
        });
    }

    addNewContact = () => {
        let signingOrder = 1;
        const isCCPresent: boolean = this.state.signingInfo.recipients.some(r => r.clientType == ClientType.CC);
        if (this.state.signingInfo.recipients.length > 0) {
            signingOrder = this.state.signingInfo.recipients.reduce((max, client) => 
                (client.signingOrder > max.signingOrder) ? client : max, this.state.signingInfo.recipients[0]).signingOrder + 1;

            // SigningOrder for empty recipient for parallel sign is 3. after recipient type is changed, it will be updated to 1 for signer/ 2 for CC
            signingOrder = this.state.signingInfo.signingType === SigningType.Parallel ? isCCPresent ? 3 : 1 : signingOrder;
        }

        let recipient = createClient("", Guid.newGuid(), ClientType.Undefined, AuthenticationType.None, signingOrder,'');
        let signingInfo = this.state.signingInfo;
        signingInfo.recipients.push(recipient);

        this.setState({ signingInfo: signingInfo },
            () => {
                this.props.updateSFData(this.state.signingInfo);
                this.props.isRecipientsUpdated(true);
                const element = document.getElementById('id-recipients-body');
                if(element && this.state.signingInfo.recipients.length > 5){
                    element.scrollTop = element.scrollHeight;
                }
            });
    }

    updateCCSigningOrder = (recipients: IClient[]) => {

        if (this.state.signingInfo.signingType == SigningType.Parallel) {
            recipients.sort((a, b) => a.signingOrder - b.signingOrder);
            return;
        }
        
        let ccSigningOrder = getHighestSOUptoLastCC(recipients) + 1;
        recipients.forEach(r => {
            if(r.clientType == ClientType.CC){
                r.signingOrder = ccSigningOrder;
            } 
        });
        recipients.sort((a, b) => a.signingOrder - b.signingOrder);

        //increment by 1 if signing order of CC and Empty are same
        if (recipients.findIndex(r => r.clientType == ClientType.CC) > -1) {
            let increment = 0; 
            recipients.forEach(r => {
                increment = r.clientType != ClientType.CC && r.signingOrder == ccSigningOrder ? 1 : increment;
                r.signingOrder += increment;
            });
        }
    }

    updateRecipients = (recipients: IClient[], updateSigningOrder: boolean, isdeleted?: boolean) => {
        let signingInfo = Object.assign({}, this.state.signingInfo);
        signingInfo.recipients = recipients;
        if(updateSigningOrder) {
            this.updateCCSigningOrder(signingInfo.recipients);
        }
        this.setState({ signingInfo: signingInfo }, () => {
            this.props.updateSFData(this.state.signingInfo);
            if (recipients.length != this.state.signingInfo.recipients.length || isdeleted) {
                this.props.isRecipientsUpdated(true)
            }
            else {
                this.props.isRecipientsUpdated(false)
            }
        });
    }
    
    getSelectedTemplate = () => {
        if(this.state.selectedMsg && this.state.selectedMsg.id > 0 && !this.props.SavedMessages[this.state.selectedMsg.id]){
            return ZERO_NUMBER;
        }
        return this.state.selectedMsg ? this.state.selectedMsg.id : undefined;
    }

    public render() {
        this.state.signingInfo.recipients.length === 0 && this.addNewContact();
        return (<div className='step-2'>
            <div>                
                <Row className='fontWeight700 fontSize15'>
                    Recipients Information
                </Row>
                <Row className="form-group padT15">
                    <Col sm={12} className='padL00 fontSize14 fontWeight600'>
                        Sender
                    </Col>
                    <Col sm={12}>
                        <Row>
                            <Col sm={6} style={{maxWidth:'395px'}} className='padL00'>
                                <Select id="dd-delegate-users"
                                    options={this.getDelegateUsers()}                                    
                                    value={this.state.signingInfo.sender.userId}
                                    arrowRenderer={()=><span data-test-auto="6095E1F5-B0B9-4607-94E8-7434685047D0"><DropdownArrow /></span>}
                                    onChange={(option) => { this.handleSenderChange(option) }}
                                    clearable={false} 
                                    clearRenderer={()=><XLg color='#212529'/>}/>
                            </Col>
                            <Col sm={3} style={{ paddingLeft: '12px',paddingRight:'12px', maxWidth:'250px'}}>
                                <Select options={this.getSenderSignOptions()}
                                    value={this.state.signingInfo ? this.state.signingInfo.senderSignOption : SenderSignOption.NO_SIGN}
                                    onChange={this.handleSenderSignOption}
                                    arrowRenderer={()=><span data-test-auto="F0130629-D675-488D-B409-C20DEFDB5C10"><DropdownArrow /></span> }
                                    clearable={false} 
                                    clearRenderer={()=><XLg color='#212529'/>}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                    <ClientAuthenticationList
                        contacts={this.props.contacts}
                        clientDetailsMode={ClientDetailsMode.Create}
                        recipientDeletable={true}
                        isEditable={true}
                        questions={this.props.questions}
                        updateRecipient={this.updateRecipient}
                        updateRecipients={this.updateRecipients}
                        signingType={this.state.signingInfo.signingType}
                        recipients={this.state.signingInfo.recipients}
                        securitySettings={this.props.companySettings.securitySettings}
                        clientAuthenticationData={this.props.clientAuthenticationData}
                        getAuthenticationParameter={this.props.getAuthenticationParameter}
                        handleSigningTypeChange={this.handleSigningTypeChange}
                        />
                <Row className="form-group">
                    <Col sm={10} className='padL00'>
                        <span
                        style={{ cursor: 'pointer', verticalAlign: 'text-bottom' }}
                        className='marR05 marB05'
                        onClick={this.addNewContact}
                        data-test-auto="9A7A982F-6AAE-4FA1-8676-F5636583B4BE">
                            <PlusCircle fill='#05386B'/>
                        </span>
                        <span className='fontSize16 fontWeight400' style={{ textDecoration: 'underline'}}>Add Recipients</span>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col sm={12} className='fontWeight700 fontSize15 padL00'>Message Configuration</Col>
                    <Col sm={12} className='fontWeight600 fontSize14 padT15 padL00'>Template</Col>
                        <Col sm={3} className='padT15 padL00'>
                            <Select id="dd-msg" 
                            className={(this.state.signingInfo?.messageId === 0  && !this.state.signingInfo?.title && !this.state.signingInfo?.message) ? 'recipient-msg-clear' : ''}
                                options={this.populateMessagesDD()}
                                clearRenderer={()=><span data-test-auto={"fe40153a-45b5-4e82-bec5-d45bcaa18bc4"}><XLg color='#212529'/></span>}
                                placeholder="Select Template"
                                arrowRenderer={()=> <span data-test-auto="1BAA181A-4995-44D1-A3DB-EA18A5266F8D"><DropdownArrow /></span>}
                                value={this.getSelectedTemplate()}
                                onChange={this.handleMessageDDChange} />
                        </Col>
                </Row>
                <Row className="form-group recipient-msg-subject">
                    <Col sm={12} className='padL00 fontSize14 fontWeight600'>Subject</Col>
                    <Col sm={10} className='padL00'>
                        <FormControl
                            type="text"
                            className='borderRadius3'
                            disabled={!this.state.isMsgEdit}
                            value={this.state.signingInfo.title}
                            placeholder='Subject'
                            onChange={(event: any) => { this.handleSubjectChange(event.target.value) }}
                            data-lpignore="true"
                            />
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col sm={12} className='padL00 fontSize14 fontWeight600'>Message</Col>
                    <Col sm={10} className='padL00'>
                        <textarea
                            ref={(msgRef) => { this.messageTextAreaRef = msgRef }}
                            rows={3} onChange={this.handleMessageChange}
                            className="width100 recipient-msg-txt-area borderRadius3"
                            placeholder='Message'
                            value={this.state.signingInfo.message}
                            disabled={!this.state.isMsgEdit}
                            />
                    </Col>
                </Row>
            </div>
        </div >);
    }
}
