import { ReportFilterType, SortDirections } from "../components/common/Filters";
import { ISignatureFlowReportFilter } from "./SignatureFlowReportState";

export interface ISignatureFlowSettings {
    messageSettings: IMessageSettings;
    delegationSettings: IDelegationSettings;
    securitySettings: ISecuritySettings;
    reminderSettings: IReminderSettings;
    clientInstructionSettings: IClientInstructionSettings;
    accessOptionsSettings: IAccessOptionsSettings;
    eventNotifySettings?: IEventNotifySettings;
    clientIdSettings: IClientIdSettings;
}

export interface IClientIdSettings {
    mandateClientId: boolean;
}

export interface IAccessOptionsSettings {
    accessOption: AccessOption;
    additionalAccess: boolean;
}

export interface IClientInstructionSettings {
    clientInstructions: IDefaultClientInstructions;
}

export interface IDefaultClientInstructions {
    defaultReminderInstruction: number;
    defaultDownloadInstruction: number;
}

export interface IMessageSettings {
    welcomeMessage: number;
}

export interface IEventNotifySettings {    
    notifyUserId : number;
}

export interface IDelegationSettings {
    delegationType: DelegationType ;
    usersAllowedToDelegate: number[];
    signerDelegation: boolean;
}

export enum DelegationType {
    NoDelegation = "NoDelegation",
    SenderDelegation ="SenderDelegation",
    DelegateToEveryone ="DelegateToEveryone"
}

export interface ISecuritySettings {
    enableSignerAuthentication: boolean;
    questionSettings: IAuthenticationQuestionSettings;
}

export interface IReminderSettings {
    signingReminder: IReminderOptions;
}

export interface IReminderOptions {
    enabled: boolean;
    reminderDays: number
}

export interface IMessage {
    id: number;
    name: string;
    body: string;
    messageType: MessageType;
    isEditable: boolean;
    subject: string;
}

export interface IDocumentType {
    id: number;
    description: string;
    expiration: number;
    isActive: boolean;
}

export enum MessageType {
    None,
    Save
}

export enum AccessOption {
    None = 0,
    Everyone = 1,
    IndividualUser = 2
}

export const initialSignatureFlowSettings: ISignatureFlowSettings = {
    messageSettings: {
        welcomeMessage: 0
    },
    delegationSettings: {
        delegationType: DelegationType.NoDelegation,
        usersAllowedToDelegate: [],
        signerDelegation: true
    },
    securitySettings: {
        enableSignerAuthentication: false,
        questionSettings: {
            defaultQuestion: 0,
            allowUsersToAdd: false
        }
    },
    reminderSettings: {
        signingReminder: {
            reminderDays: 7,
            enabled: false
        } 
    },
    clientInstructionSettings: {
        clientInstructions: {
            defaultReminderInstruction: 0,
            defaultDownloadInstruction: 0
        }
    },
    accessOptionsSettings: {
        accessOption: AccessOption.None,
        additionalAccess: false
    },
    clientIdSettings: {
        mandateClientId: false
    }
}

export const initialEventNotifySettings: IEventNotifySettings = {
    notifyUserId : 0
}

export const initialMessage = {
    id: 0,
    name: '',
    body: '',
    messageType: MessageType.Save,
    isEditable: false,
    subject: '',
}

export interface IDocumentType {
    id: number;
    description: string;
    expiration: number;
    isActive: boolean;
}

export interface IAuthenticationQuestionSettings {
    defaultQuestion: number;
    allowUsersToAdd: boolean;
}

export interface IAuthenticationQuestion {
    id: number;
    title: string;
    text: string;
    questionType: QuestionType;
}

export enum QuestionType {
    None = 0,
    Default = 1,
    Custom = 2
}

export const initialQuestion = {
    id: 0,
    title: '',
    text: '',
    questionType: QuestionType.Custom
}

export const initialSFFilter : ISignatureFlowReportFilter = {
    name: '',
    searchText: '',
    sort: {
        column: '',
        direction: SortDirections.None
    },
    fields: {},
    isDefault: 0,
    filterType: ReportFilterType.None,
    isDefaultFilter: false,
    isMasterFilter: false
}