import * as React from 'react';
import { Button, Nav } from 'react-bootstrap';
import { IDocumentType } from '../../../models/SignatureFlowSettings';
import { DocumentTypePopup } from '../DocumentTypePopup';
import { isArray } from 'lodash';
import { PencilIcon, PlusLg } from '../../svg/CustomSvgIcons';


export interface IDocumentTypeProps {
    resourceId: string;
    documentTypes: IDocumentType[];
    defaultMessage: number;
    onSaveDocumentType: (id: number, value: string, expiration: number, isActive: boolean) => void;
    onUpdateDocumentType: (id: number, value: string, expiration: number, isActive: boolean) => void;
    showDocumentTypePopUp: boolean;
}

export interface IDocumentTypeState {
    showDocumentTypePopUp: boolean;
    isUpdateDocumentType: boolean;
    selectedDocumentType: IDocumentType;
    buttonText: string;
    title: string;
}

export class DocumentType extends React.Component<IDocumentTypeProps, IDocumentTypeState> {
    state = {
        showDocumentTypePopUp: false,
        isUpdateDocumentType: false,
        selectedDocumentType: {
            description: '',
            expiration: 0,
            id: 0,
            isActive: false
        },
        buttonText: '',
        title: ''
    };

    UNSAFE_componentWillReceiveProps(nextProps: IDocumentTypeProps) {
        this.setState({
            showDocumentTypePopUp: nextProps.showDocumentTypePopUp
        });
    }

    onCancelButtonClick = () => {
        this.setState({
            showDocumentTypePopUp: false,
            isUpdateDocumentType: false
        });
    }

    addDocumentType = () => {
        this.setState({
            showDocumentTypePopUp: true,
            selectedDocumentType: {
                description: '',
                expiration: 0,
                id: 0,
                isActive: false
            },
            buttonText: 'Add',
            title: 'Add Document Type',
            isUpdateDocumentType: false
        })
    }

    ediDocumentType = (id: number, value: string, expiration: number, isActive: boolean) => {
        this.setState({
            showDocumentTypePopUp: true,
            selectedDocumentType: {
                description: value,
                expiration: expiration,
                id: id,
                isActive: isActive
            },
            buttonText: 'Save',
            title: 'Edit Document Type',
            isUpdateDocumentType: true
        })
    }

    saveDocumentType = (id: number, value: string, expiration: number, isActive: boolean) => {
        if (this.state.isUpdateDocumentType) {
            this.props.onUpdateDocumentType(id, value, expiration, isActive);
        } else {
            this.props.onSaveDocumentType(id, value, expiration, isActive);
        }
    }

    public render() {
        var listHeightStyle = this.props.documentTypes && isArray(this.props.documentTypes) && this.props.documentTypes.length > 4 ? "calc(37px * 4 - 2px)" : "calc(37px * 4 - 1px)";
        return <div data-resource-id={this.props.resourceId} className='document-type-container'>
            <div className="row font14" style={{ position: "relative" }}>
                <div className="col-sm-6 col-lg-4" style={{ marginLeft: 17 }}>
                    <div className="row dispFR">
                        <div className="col-sm-10 padL00 float-left">
                            <h4 className="text-primary sigflow-settings-heading">Signature Document Type</h4>
                        </div>
                    </div>
                    <div className="row dispFR settings-list-container custom-scroll-bar" style={{ marginTop: 25, overflowY: 'auto', height: listHeightStyle, width:'367px' ,border: 'rgb(221, 221, 221) 1px solid' }}>
                        <div>
                            <ul className="list-group">
                                {
                                    this.props.documentTypes && isArray(this.props.documentTypes) && this.props.documentTypes.map((documentType, index) => {
                                        const liClassName = "custom-list-group-item-singleline padT07-i " + 
                                                            (index === 0 ? 'bord-t-0-i ' : '') +
                                                            ((this.props.documentTypes.length < 4 && index === this.props.documentTypes.length - 1) ? "bord-b-1 " : "");
                                        
                                        return (<li
                                                    className={liClassName}
                                                    title={documentType.description}
                                                    style={!documentType.isActive ? { backgroundColor: "rgb(241, 241, 241)" } : {}}
                                                >
                                                    <div className="width100 row" style={{margin:0} }>
                                                        <div className={"col-md-9 nopadding overflowHidden font16 ellipsis"}>
                                                            {documentType.description}
                                                        </div>
                                                        <div className="col-md-3" style={{paddingTop:'3px'}}>
                                                            <a href='javascript:'>
                                                                <span className='float-right' style={{ marginLeft: "15px"}}
                                                                    onClick={() => { this.ediDocumentType(documentType.id, documentType.description, documentType.expiration, documentType.isActive) }}
                                                                    data-test-auto="7DCCE7A0-114D-42C8-ACB0-792313E539FA">
                                                                        <PencilIcon color='#05386B' marginBottom={'6px'}/><span style={{paddingLeft: '8px', color:'#05386B'}}>Edit</span></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <Button
                        data-test-auto="552FD0AC-186A-4801-890D-ACB009222F88"
                        variant='default'
                        className='btn-white settings-add-btn'
                        style={{marginLeft:'-15px'}}
                        onClick={this.addDocumentType}
                        title="Add Document Type">
                        <PlusLg  marginBottom={'4px'}/><span style={{paddingLeft:'5px'}}>Add</span>
                    </Button>
                </div>
            </div>

            <DocumentTypePopup
                show={this.state.showDocumentTypePopUp}
                documentType={this.state.selectedDocumentType}
                documentTypes={this.props.documentTypes}
                onSaveDocumentType={this.saveDocumentType}
                onCancel={this.onCancelButtonClick}
                buttonText={this.state.buttonText}
                title={this.state.title}
            />

        </div>;
    }
}

export default DocumentType;
