import React from 'react';
import { LoaderLogoSvg } from './svg/CustomSvgIcons';

const LoadingView: React.FC = () => (
    <div className='login-container flex-column-center-all'>
        <LoaderLogoSvg/>
        <div className='loading-text' data-title="dot-spin">
            <div className="dot-spin"></div>
        </div>
    </div>
)

LoadingView.displayName = 'LoadingView'

export default LoadingView
