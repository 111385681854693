import * as React from 'react';
import { Row } from 'react-bootstrap';
import { ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';
import * as Constants from '../../helper/Constants';

export interface IMandateClientIdSettingsProps {
    resourceId: string;
    signatureFlowSettings: ISignatureFlowSettings;
    updateCompanySettings: (signatureFlowSettings: ISignatureFlowSettings, settingsType?: Constants.typeOfSettings) => void;
}


export const MandateClientIdSettings: React.FC<IMandateClientIdSettingsProps> = props => {

    const handleSMandateClientIdChange = (event: any) => {
        let companySettings = props.signatureFlowSettings;
        companySettings.clientIdSettings.mandateClientId = event.target.checked;
        props.updateCompanySettings(companySettings, Constants.typeOfSettings.ClientId);
    }

    return (
        <div className="new-main-content mandate-client-id" data-resource-id={props.resourceId}>
            <div className="row" style={{ position: "relative", marginLeft:'0px' }}>
                <div style={{ marginLeft: 15, paddingBottom:'5px',paddingTop:'px' }}>
                    <div className="row">
                        <div className="col-sm-10 padL00 float-left">
                            <h4 className='sigflow-settings-heading padB15'>Mandate Client id</h4>
                        </div>
                    </div>

                    <div className="row" style={{ alignItems: "center" }}>
                        <div style={{width:'367px', marginLeft:'15px'}}>
                            <Row>
                                <div style={{width:'49px'}}>
                                    <label className="toggleSwitch">
                                        <input type="checkbox"
                                            checked={props.signatureFlowSettings.clientIdSettings.mandateClientId}
                                            onChange={handleSMandateClientIdChange}></input>
                                        <span className="columnFilterSlider round"></span>
                                    </label>
                                </div>
                                <div className="text-left" style={{paddingTop: '8px',paddingBottom:'42px'}}>
                                    <div className="font16"> Mandate Client id </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}