import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ExpandBy } from 'react-bootstrap-table';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument, ClientTypes, ISignerModel, StampingStatus } from '../../../models/SignatureFlowReportState'
import { BoxMinusIcon, BoxPlusIcon } from '../../svg/CustomSvgIcons';
import { SigningType } from 'src/SignatureFlow/models/SigningInfo';
import { GetESignStatusElement, GetESignStatusText } from '../../helper/HelperFunctions';

let moment = require('moment');

interface ISignerModalProps {
    show: boolean;
    onCancel: () => void;
    signatureReport: ISignatureFlowReportDocument;
    pageTitle: string;
}
interface IColumnValues {
    name: string;
    recipientEmail: string,
    recipientType: ClientTypes,
    isSigned: number,
    stampingStatus: StampingStatus,
    signedDate: Date,
    signingOrder: number,
    clientId: number,
    delegateeId?: number;
}

interface IDelegateeProps {
    delegatee: ISignerModel;
    pageTitle: string;
    signingType: SigningType;
    delegator: ISignerModel;
}


export class SignatureFlowESignDetailsModal extends React.Component<ISignerModalProps, {}>{
    constructor(props: ISignerModalProps) {
        super(props);

        this.defaultType = this.defaultType.bind(this);
        this.signingOrderType = this.signingOrderType.bind(this);
    }

    defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    signingOrderType(cell: any, row: any) {
        if(this.props.signatureReport && this.props.signatureReport.signingType !=  SigningType.Parallel){
            return row.recipientType === ClientTypes.Sender ? <div title=''></div> : <div title={cell} className="ellipsis">{cell}</div>;
        }
        return <div title=''></div>
    }

    signerDateType(cell: any, row: any) {
        if (cell === "N/A")
            return "N/A";
        else
            return <div title={cell} className="ellipsis">{cell}</div>;
    }

    signatureStatusDataType = (cell: any, row: any) => {
        let signatureStatus: any;
        switch (row.recipientType) {
            case ClientTypes.Delegatee:
                signatureStatus = GetESignStatusElement(row.isSigned, row.stampingStatus);
                break;
            case ClientTypes.Signer:
                if (row.delegateeId > 0) {
                    const delegatee = this.props.signatureReport && this.props.signatureReport.signerInfo
                        .find(x => row.delegateeId == x.clientId);
                    if (delegatee) {
                        signatureStatus = <span className='signature-status-delegated'>Delegated</span>;
                    }
                }
                else {
                    signatureStatus = GetESignStatusElement(row.isSigned, row.stampingStatus);
                }
                break;

            case ClientTypes.Sender:
                if (row.delegateeId > 0) {
                    const delegatee = this.props.signatureReport && this.props.signatureReport.signerInfo
                        .find(x => row.delegateeId == x.clientId);
                    if (delegatee) {
                        signatureStatus = <span className='signature-status-delegated'>Delegated</span>;
                    }
                }
                else {
                    signatureStatus = row.signingOrder != -1 ? 
                    GetESignStatusElement(row.isSigned, row.stampingStatus) :
                    "N/A";
                }
                break;

            default:
                signatureStatus = "N/A";
        }
        return signatureStatus;
    }

    isExpandableRow = (row: any) => {
        if (row.delegateeId > 0) return true;
        else return false;
    }

    expandComponent = (row: any) => {
        if (this.props.signatureReport && this.props.signatureReport.signerInfo.length > 0) {
            const delegateeDetails = this.props.signatureReport.signerInfo.filter(x => x.signerType == ClientTypes.Delegatee);
            if (delegateeDetails.length) {
                const delegatee = delegateeDetails.find(x => x.clientId == row.delegateeId);
                const delegator = this.props.signatureReport.signerInfo.find(x => x.clientId == row.clientId);
                if (delegatee) {
                    return (
                        <DelegateeTable 
                        delegatee={delegatee} 
                        pageTitle={this.props.pageTitle} 
                        signingType={this.props.signatureReport.signingType}
                        delegator={delegator}/>
                    );
                }
            }
        }
        return (<div></div>);
    }

    expandColumnComponent = ({ isExpandableRow, isExpanded }: any) => {

        if (isExpandableRow) {
            return isExpanded ? <BoxPlusIcon fill='#05386B'/> : <BoxMinusIcon fill='#05386B'/>;
        }

        return null;
    }

    signatureStatusToolTip = (cell: any, row: any) => {
        let signatureStatusToolTip: any;
        switch (row.recipientType) {
            case ClientTypes.Delegatee:
                signatureStatusToolTip = GetESignStatusText(row.isSigned, row.stampingStatus);
                break;
            case ClientTypes.Signer:
                if (row.delegateeId > 0) {
                    const delegatee = this.props.signatureReport && this.props.signatureReport.signerInfo
                        .find(x => row.delegateeId == x.clientId);
                    if (delegatee) {
                        signatureStatusToolTip = "Delegated to " + delegatee.signerEmail;
                    }
                }
                else {
                    signatureStatusToolTip = GetESignStatusText(row.isSigned, row.stampingStatus);
                }
                break;
            case ClientTypes.Sender:
                if (row.delegateeId > 0) {
                    const delegatee = this.props.signatureReport && this.props.signatureReport.signerInfo
                        .find(x => row.delegateeId == x.clientId);
                    if (delegatee) {
                        signatureStatusToolTip = "Delegated";
                    }
                }
                else {
                    signatureStatusToolTip = row.signingOrder != -1 ? GetESignStatusText(row.isSigned, row.stampingStatus) : "N/A";
                }
                break;

            default:
                signatureStatusToolTip = "N/A";
        }
        return signatureStatusToolTip;
    }

    public render() {
        const expandBy: ExpandBy = "column";
        const options = {
            expandBy: expandBy
        };

        let signedDetailsColumn = [
            {
                header: 'Recipient Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultType,
                width: '150px',
                toolTip: false,
                className:"table-column-header"
            },
            {
                header: 'Email',
                key: 'recipientEmail',
                isKey: false,
                dataFormat: this.defaultType,
                width: '250px',
                toolTip: false,
                className:"table-column-header"
            },
            {
                header: 'Sequence Order',
                key: 'signingOrder',
                isKey: false,
                dataFormat: this.signingOrderType,
                width: '150px',
                toolTip: false,
                editable: false,
                isHidden: false,
                className: 'table-column-header',
            },
            {
                header: 'Status',
                key: 'signedStatus',
                isKey: true,
                dataFormat: this.signatureStatusDataType,
                width: '150px',
                toolTip: this.signatureStatusToolTip,
                className:"table-column-header"
            },
            {
                header: 'Date',
                key: 'signedDate',
                isKey: false,
                dataFormat: this.signerDateType,
                width: 'auto',
                toolTip: false,
                className:"table-column-header"
            }
        ];

        let signerDetailsData: IColumnValues[] = [];

        if (this.props.signatureReport && this.props.signatureReport.signerInfo) {

            let signerRecipients = this.props.signatureReport.signerInfo
                .filter(x => x.signerType == ClientTypes.Signer)
                .sort((a: any, b: any) => {
                    if (a.clientId > b.clientId) return 1;
                    else return -1;
                });

            let senderAsSigner = this.props.signatureReport.signerInfo.filter(x => x.signerType == ClientTypes.Sender &&
                (x.order >= 0 || (x.delegateeId && x.delegateeId > 0)))[0];

            if (senderAsSigner) {
                signerRecipients.push(senderAsSigner);
            }

            signerRecipients.map((value, index) => {
                signerDetailsData.push({
                    name: value.name,
                    recipientEmail: value.signerEmail,
                    recipientType: value.signerType,
                    isSigned: value.isSigned,
                    stampingStatus: value.stampingStatus,
                    signedDate: (value.isSigned && value.signedOn !== null) ? moment.utc(value.signedOn).local().format('MM/DD/YYYY') : "N/A",
                    signingOrder: value.delegateeId > 0 ? this.props.signatureReport.signerInfo.find(x => value.delegateeId == x.clientId).order : value.order ,
                    clientId: value.clientId,
                    delegateeId: value.delegateeId
                });
            });
        }

        let modalBody = this.props.signatureReport ?
            (
                signedDetailsColumn = this.props.pageTitle === "Recycle Bin" ? signedDetailsColumn.filter(x => x.header !== "Sequence Order") : signedDetailsColumn, 
                <BootstrapTable data={signerDetailsData} expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    bordered={false}
                    expandColumnOptions={{
                        expandColumnVisible: true,
                        expandColumnComponent: this.expandColumnComponent,
                        columnWidth: 30
                    }} options={options}
                    containerClass='report-table-container'
                    tableHeaderClass='report-header-border table-header-font '
                    trStyle={{borderBottom:'1px solid #A6A9AC', height: '32px'}}
                    >
                    {signedDetailsColumn.map((value, index) => {
                        return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat} className={value.className ? value.className : ''}  width={value.width} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
                    })}
                </BootstrapTable>) :
            (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);
        
        let modalClassName = this.props.pageTitle === "Recycle Bin" ? "" : this.props.pageTitle + "-esign-details-modal";

        return <Bs.Modal centered={true} className={"sf-esign-details-modal " + modalClassName } show={this.props.show} onHide={this.props.onCancel} backdrop="static">
            <Bs.Modal.Header closeButton>
                <Bs.Modal.Title>
                   E-Sign Details
                   </Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Form>
                <Bs.Modal.Body>
                    {modalBody}
                </Bs.Modal.Body>
            </Bs.Form>
            <Bs.Modal.Footer>
                <Bs.Button
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    onClick={this.props.onCancel}>
                    Cancel
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>
    }
}



export class DelegateeTable extends React.Component<IDelegateeProps, {}> {
    render() {
        return (
            <table id="delegatee-table">
                <tr>
                    <td width={'150'} title={this.props.delegatee.name}>{this.props.delegatee.name}</td>
                    <td width={'250'} title={this.props.delegatee.signerEmail + " (" + ClientTypes[this.props.delegatee.signerType] + ")"}>
                        {this.props.delegatee.signerEmail + " (" + ClientTypes[this.props.delegatee.signerType] + ")"}</td>
                    { this.props.pageTitle === "Recycle Bin" ? null : 
                        this.props.signingType == SigningType.Parallel || this.props.delegator.signerType == ClientTypes.Sender ? <td width={'150'}></td> :
                        <td width={'150'} title={this.props.delegatee.order.toString()}>
                        {this.props.delegatee.order}</td>    
                    } 
                    <td width={'150'} title={GetESignStatusText(this.props.delegatee.isSigned, this.props.delegatee.stampingStatus)}>
                        {GetESignStatusElement(this.props.delegatee.isSigned, this.props.delegatee.stampingStatus)}</td>
                    <td title={(this.props.delegatee.isSigned && this.props.delegatee.signedOn !== null) ?
                        moment.utc(this.props.delegatee.signedOn).local().format('MM/DD/YYYY') : "N/A"}>
                        {(this.props.delegatee.isSigned && this.props.delegatee.signedOn !== null) ?
                            moment.utc(this.props.delegatee.signedOn).local().format('MM/DD/YYYY') : "N/A"}</td>
                </tr>
            </table>);
    }
}

export default SignatureFlowESignDetailsModal;