import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import {
    ClientTypes,
    ISignatureFlowReportDocument,
    ISignerModel,
    SignatureFlowReportSignatureStatus,
} from '../../../models/SignatureFlowReportState';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { ResendMail, CopyIcon } from '../../svg/CustomSvgIcons';
import copy from 'copy-to-clipboard';
import { AuthenticationType } from 'src/SignatureFlow/models/SigningInfo';

interface IResendAccessLinkProps {
    show: boolean;
    onCancel: () => void;
    model: ISignatureFlowReportDocument;
    onResendAccessLink: (model: ISignerModel, callBack: () => void) => void;
}

const Constants = {
    sending: 'Sending Access Link...',
    loading: 'Loading, please wait...',
    copySuccess: 'Access link has been copied',
};

export const ResendAccessLinkModal: React.FC<IResendAccessLinkProps> = props => {
    const [message, setMessage] = React.useState(Constants.loading);
    const [loading, setLoading] = React.useState(false);
    const { model } = props;

    const signedDetails = React.useMemo(() => {
        return model && model.signerInfo && model.signerInfo.length > 0;
    }, [model]);

    React.useEffect(() => {
        if (!props.show) {
            setMessage(Constants.loading);
        }
    }, [props]);

    const onResendAccessLink = (signerObj: any) => {
        setLoading(true);
        setMessage(Constants.sending);
        let signerModel = props.model.signerInfo.find(
            x => x.clientGuid == signerObj.clientGuid && x.signerType == signerObj.signerType
        );
        props.onResendAccessLink(signerModel, () => {
            setLoading(false);
        });
    };

    const copyToClipBoard = (link: string) => {
        copy(link);
        VenusNotifier.Success(Constants.copySuccess, null);
    };

    const canClientResendAccessLink = () => {
        const signatureStatus: any = SignatureFlowReportSignatureStatus[props.model.signatureStatus];
        return [
            SignatureFlowReportSignatureStatus.OutForSignature,
            SignatureFlowReportSignatureStatus.PartiallySigned,
            SignatureFlowReportSignatureStatus.ESigned,
        ].includes(signatureStatus);
    };

    const canClientCopyAccessLink = (signerObj: any) => {
        return AuthenticationType.None != signerObj.authenticationType;
    };

    const canClickCopy = (signer: any) => {
        canClientCopyAccessLink(signer) && copyToClipBoard(signer.accessLink);
    };

    return (
        <Modal
            centered={true}
            show={props.show}
            onHide={props.onCancel}
            className='resend-access-link-modal'
            backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Resend Access Link</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto', maxHeight: '233px' }}>
                <LoadingOverlay style={{ minHeight: 100 }}>
                    {signedDetails ? (
                        <ul style={{ padding: 10 }}>
                            {props.model.signerInfo
                                .filter(x => x.order >= 0 && x.signerType != ClientTypes.CC)
                                .map((i, index) => (
                                    <React.Fragment key={index}>
                                        <li
                                            key={index}
                                            style={{ width: '-webkit-fill-available', listStyle: 'none' }}>
                                            <div className='resend-access-link-email'>
                                                <span>{i.signerEmail}</span>
                                            </div>
                                            <ResendMail />
                                            <span
                                                onClick={() => onResendAccessLink(i)}
                                                className={
                                                    'resend-access-link ' +
                                                    (!canClientResendAccessLink() ? '' : 'resend-access-link-disabled')
                                                }>
                                                Resend Link
                                            </span>
                                            <CopyIcon />
                                            <span
                                                onClick={() => canClickCopy(i)}
                                                className={
                                                    'copy-access-link ' +
                                                    (canClientCopyAccessLink(i) ? '' : 'resend-access-link-disabled')
                                                }
                                                title={canClientCopyAccessLink(i) ? 'Copy Link Address' : ''}>
                                                Copy
                                            </span>
                                        </li>
                                        <br />
                                    </React.Fragment>
                                ))}
                        </ul>
                    ) : (
                        model && (
                            <Alert variant='warning'>
                                <i
                                    className='fas fa-exclamation-triangle'
                                    style={{ marginRight: '5px', color: '#666' }}></i>
                                No details to show!
                            </Alert>
                        )
                    )}
                    <Loader loading={loading} text={message}/>
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={props.onCancel}
                    variant='default'
                    className='btn-white modal-footer-button-cancel'
                    data-test-auto='BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0'>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
