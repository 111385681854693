import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime } from '../../../components/helper/HelperFunctions';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import {
    ISignatureFlowReportDocument,
    SignatureFlowDocumentEvent,
    IClientHubClientTrackingModel,
    ClientHubEvents,
} from '../../../models/SignatureFlowReportState';
import { MailEvent } from '../../../components/common/MailEvent';
import { ClientHubEventValue, SignatureReportClientTrackingEvents } from '../../helper/Constants';
import Pagination from '../../common/Pagination';
let moment = require('moment');

interface IClientTrackingProps {
    show: boolean;
    onCancel: () => void;
    selectedDocument: ISignatureFlowReportDocument;
    clientHubEvents: IClientHubClientTrackingModel[];
}

enum EventType {
    None = 0,
    Signatures = 1,
    ClientPortal = 2,
}

const SignatureFlowReportClientTrackingModal: React.FC<IClientTrackingProps> = props => {
    const [pageNo, setPageNo] = useState(1);
    const [sortedData, setSortedData] = useState<any[]>([]);
    const allDataLengthRef = useRef(0);

    const onPageChange = useCallback((page: number, pageSize: number) => {
        setPageNo(page);
    }, []);

    const onCloseModal = useCallback(() => {
        setPageNo(1);
        props.onCancel();
    }, [props.onCancel]);

    const getMailEvent = (mailEvent: any) => {
        switch (mailEvent) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return ` - ${MailEvent[mailEvent]}`;
            default:
                return '';
        }
    };

    const getReminderEventName = (cell: any, mailEvent: any, email) => {
        const isManualReminder = cell === SignatureFlowDocumentEvent.ReminderSendForSigning;
        switch (mailEvent) {
            case 5:
            case 6:
                return `${isManualReminder ? 'Manual Reminder email' : 'Automated Reminder email'} - ${
                    MailEvent[mailEvent]
                }`;
            default:
                return `${SignatureReportClientTrackingEvents[cell]} to ${email}`;
        }
    };

    const defaultType = (cell: any, row: any) => {
        return cell;
    };

    const actedOnType = (cell: any, row: any) => {
        return GetFormatedDateTime(cell);
    };

    const actedByType = (cell: any, row: any) => {
        const actedByEmail = cell.emailAddress ? cell.emailAddress.replace(/null/g, '') : '';
        const actedByName = cell.firstName ? cell.firstName.replace(/null/g, '') : '';
        const mailEvent = row.eventData?.mailEventId ? row.eventData.mailEventId : MailEvent.None;

        if (row.type === EventType.ClientPortal) {
            return cell ? cell : '';
        }

        switch (row.eventId) {
            case SignatureFlowDocumentEvent.Emailed:
            case SignatureFlowDocumentEvent.SignatureStampingFailed:
            case SignatureFlowDocumentEvent.DeliveryFailed:
            case SignatureFlowDocumentEvent.AccessCodeEmail:
            case SignatureFlowDocumentEvent.ClientAccessLocked:
            case SignatureFlowDocumentEvent.AccessLinkEmailedToDelegatee:
                return 'System';
            case SignatureFlowDocumentEvent.Viewed:
            case SignatureFlowDocumentEvent.EmailedToDelegatee:
            case SignatureFlowDocumentEvent.DocumentSigned:
            case SignatureFlowDocumentEvent.SigningCompletionMail:
            case SignatureFlowDocumentEvent.DeclinedEmail:
            case SignatureFlowDocumentEvent.SignedAndDownloaded:
            case SignatureFlowDocumentEvent.SignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.PartialSignedDocumentDownloaded:
                return actedByName != '' ? actedByName : actedByEmail;
            default:
                switch (mailEvent) {
                    case MailEvent.Bounce:
                    case MailEvent.Dropped:
                        return 'System';
                    default:
                        return actedByEmail;
                }
        }
    };

    const eventIdType = (cell: any, row: any) => {
        let eventName = '';
        let mailEvent = row.eventData.mailEventId;
        const recipientEmail =
            row.eventData && row.eventData.recipientInfo && row.eventData.recipientInfo.emailAddress
                ? row.eventData.recipientInfo.emailAddress
                : '';
        const actedByEmail = row.actedBy?.emailAddress ? row.actedBy.emailAddress : '';

        if (row.type === EventType.ClientPortal) {
            switch (cell) {
                case ClientHubEvents.MergedEmailId:
                    eventName = ClientHubEventValue[cell].replace('<EmailID>', row.eventData?.emailId);
                    break;
                case ClientHubEvents.UnMergedEmailId:
                    eventName = ClientHubEventValue[cell].replace('<EmailID>', row.eventData?.emailId);
                    break;
                case ClientHubEvents.EmailIDUpdated:
                    eventName = ClientHubEventValue[cell]
                        .replace('<OldEmailID>', row.eventData?.oldEmailId)
                        .replace('<NewEmailID>', row.eventData?.newEmailId);
                    break;
                case ClientHubEvents.DocumentDownloadedFromView:
                    eventName = ClientHubEventValue[cell].replace('<DocumentName', row.eventData?.fileName);
                    break;
                default:
                    eventName = ClientHubEventValue[cell];
                    break;
            }
            return eventName;
        }

        switch (cell) {
            case SignatureFlowDocumentEvent.DocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.AllDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.SignedDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.AllSignedDocumentDownloadedByCPA:
            case SignatureFlowDocumentEvent.SignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.AllSignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.PartialSignedDocumentDownloaded:
            case SignatureFlowDocumentEvent.SignedAndDownloaded:
                eventName = `${SignatureReportClientTrackingEvents[cell]} (${row.eventData.fileName})`;
                break;

            case SignatureFlowDocumentEvent.CancelledDocument:
            case SignatureFlowDocumentEvent.DownloadEmail:
            case SignatureFlowDocumentEvent.Filed:
            case SignatureFlowDocumentEvent.Archived:
            case SignatureFlowDocumentEvent.RestoreFromRecycle:
            case SignatureFlowDocumentEvent.RestoreFromArchive:
            case SignatureFlowDocumentEvent.DeliveryFailed:
            case SignatureFlowDocumentEvent.BulkDownloadsDownloadedByCPA:
                eventName = SignatureReportClientTrackingEvents[cell];
                break;

            case SignatureFlowDocumentEvent.RestoreFromRecycle:
                eventName = SignatureReportClientTrackingEvents[cell];
                break;
            case SignatureFlowDocumentEvent.DeclinedEmail:
                eventName = `${SignatureReportClientTrackingEvents[cell]} ${getMailEvent(
                    mailEvent
                )} - Mailed To ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.SigningCompletionMail:
            case SignatureFlowDocumentEvent.DelegationUpdated:
                eventName = `${SignatureReportClientTrackingEvents[cell]} to ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.EmailedToDelegatee:
                eventName = `${SignatureReportClientTrackingEvents[cell]} ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.AccessLinkEmailed:
                eventName = `${SignatureReportClientTrackingEvents[cell]} - ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.Viewed:
            case SignatureFlowDocumentEvent.DocumentSigned:
            case SignatureFlowDocumentEvent.DelegationCancelled:
                eventName = `${SignatureReportClientTrackingEvents[cell]} - ${actedByEmail}`;
                break;
            case SignatureFlowDocumentEvent.ClientAuthenticationModified:
                eventName = `${SignatureReportClientTrackingEvents[cell]} for ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.ReminderSendForSigning:
            case SignatureFlowDocumentEvent.AutomatedSigningReminderMailStatus:
                eventName = getReminderEventName(cell, mailEvent, recipientEmail);
                break;
            case SignatureFlowDocumentEvent.Emailed:
            case SignatureFlowDocumentEvent.AccessCodeEmail:
            case SignatureFlowDocumentEvent.AccessLinkEmailedToDelegatee:
                eventName = `${SignatureReportClientTrackingEvents[cell]} - ${MailEvent[mailEvent]} - ${recipientEmail}`;
                break;
            case SignatureFlowDocumentEvent.ClientAccessLocked:
                eventName = `${SignatureReportClientTrackingEvents[cell]} to ${actedByEmail}`;
                break;
            case SignatureFlowDocumentEvent.Recycled:
                eventName = 'Delete Document';
                break;
            default:
                eventName = `${SignatureReportClientTrackingEvents[cell]} ${getMailEvent(mailEvent)}`;
                break;
        }
        if (row.eventData.isAccessedFromOneHub) {
            eventName = 'Client Portal: ' + eventName;
        }
        return eventName;
    };

    const columns = [
        {
            header: 'Event',
            key: 'eventId',
            isKey: true,
            dataFormat: eventIdType,
            columnClassName: 'overflowTextClientTracking',
            className: 'table-column-header',
            dataSort: true,
            width: '340px',
            toolTip: true,
            hidden: false,
        },
        {
            header: 'By',
            key: 'actedBy',
            isKey: false,
            dataFormat: actedByType,
            columnClassName: 'overflowTextClientTracking',
            className: 'table-column-header',
            dataSort: true,
            width: 'auto',
            toolTip: true,
            hidden: false,
        },
        {
            header: 'Date',
            key: 'actedOn',
            isKey: false,
            dataFormat: actedOnType,
            columnClassName: 'overflowTextClientTracking',
            className: 'table-column-header',
            dataSort: true,
            toolTip: true,
            width: 'auto',
            hidden: false,
        },
        {
            header: 'Data',
            key: 'eventData',
            isKey: false,
            hidden: true,
            dataFormat: defaultType,
            columnClassName: '',
            className: '',
            dataSort: false,
            width: 'auto',
            toolTip: false,
        },
    ];

    const getClientTrackingEvents = () => {
        const events =
            props.selectedDocument && props.selectedDocument.clientTracking
                ? props.selectedDocument.clientTracking
                : [];

        const data = events.filter(x => x.eventId != SignatureFlowDocumentEvent.DownloadEmail);

        const downloadMailData = events.filter(
            x => x.eventId == SignatureFlowDocumentEvent.DownloadEmail && x.eventData.mailEventId == MailEvent.None
        );

        if (downloadMailData.length > 0) {
            downloadMailData.map(x => {
                data.push(x);
            });
        }

        const allEvents =
            data.length > 0
                ? data.map((model, index) => {
                      return {
                          eventId: model.eventId,
                          actedBy: model.actedBy,
                          actedOn: model.actedOn,
                          eventData: model.eventData,
                          type: EventType.Signatures,
                      };
                  })
                : [];
        return allEvents;
    };

    const getClientHubEvents = () => {
        const events =
            props.clientHubEvents && props.clientHubEvents.length > 0
                ? props.clientHubEvents.map((model, index) => {
                      return {
                          eventId: model.eventId,
                          actedBy: model.actedBy,
                          actedOn: model.actedOn,
                          eventData: model.eventData,
                          type: EventType.ClientPortal,
                      };
                  })
                : [];
        return events;
    };

    const calculateSortedData = useCallback(() => {
        const signatureEvents = getClientTrackingEvents();
        const clientHubEvents = getClientHubEvents();

        const allEvents = [...signatureEvents, ...clientHubEvents];
        allDataLengthRef.current = allEvents.length;

        allEvents.sort((a: any, b: any) => {
            const dateA = moment.utc(a.actedOn).local();
            const dateB = moment.utc(b.actedOn).local();
            return dateA - dateB;
        });

        const start = 10 * (pageNo - 1);
        const end = Math.min(start + 10, allEvents.length);
        return allEvents.slice(start, end);
    }, [getClientTrackingEvents, getClientHubEvents, pageNo, props]);

    useEffect(() => {
        setSortedData(calculateSortedData());
    }, [props, pageNo]);

    const modalBody = useMemo(() => {
        return props.selectedDocument?.clientTracking ? (
            <BootstrapTable
                data={sortedData}
                striped
                containerClass="report-table-container"
                tableHeaderClass="report-header-border"
                bordered={false}
                pagination={false}
            >
                {columns.map((value, index) => {
                    return (
                        <TableHeaderColumn
                            key={index}
                            hidden={value.hidden}
                            isKey={value.isKey}
                            dataField={value.key}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            className={value.className ? value.className : ''}
                            dataSort={true}
                            columnTitle={value.toolTip}
                        >
                            {value.header}
                        </TableHeaderColumn>
                    );
                })}
            </BootstrapTable>
        ) : (
            <OverlayLoader show={props.show} text={'Loading, please wait...'} />
        );
    }, [sortedData, pageNo]);

    return (
        <Bs.Modal
            className="client-tracking-modal"
            show={props.show}
            centered={true}
            backdrop="static"
            onHide={() => {
                onCloseModal();
            }}
        >
            <Bs.Modal.Header closeButton>
                <Bs.Modal.Title>Client Tracking History</Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Modal.Body>
                <div>{modalBody}</div>
                <div style={{ height: '35px', marginTop: '20px' }}>
                    <Pagination
                        totalCount={allDataLengthRef.current}
                        pageIndex={pageNo}
                        pageSize={10}
                        defaultPageSize={10}
                        goToPage={onPageChange}
                        showpageCount={true}
                        ShowListAndGoto={false}
                    />
                </div>
            </Bs.Modal.Body>
            <Bs.Modal.Footer>
                <Bs.Button variant="default" className="btn-white modal-footer-button-cancel" onClick={onCloseModal}>
                    Close
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>
    );
};

export { SignatureFlowReportClientTrackingModal };
