import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BootstrapTable, TableHeaderColumn, DataAlignType } from 'react-bootstrap-table';
import { OverlayLoader } from '../../../components/helper/OverlayLoader';
import { ISignatureFlowReportDocument, ClientTypes, ISignerModel, SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { isValidEmailAddress } from '../../../components/helper/Validations';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import { duplicateRecipient, isNullOrEmpty } from '../../helper/HelperFunctions';
import { PencilIconFill } from '../../svg/CustomSvgIcons';
import { SigningType } from 'src/SignatureFlow/models/SigningInfo';


interface ISignerModalProps {
    show: boolean;
    onCancel: () => void;
    signatureReport: ISignatureFlowReportDocument;
    updateSigners: (signatureData: ISignatureFlowReportDocument, signers: ISignerModel[]) => void;
}

interface SignatureFlowReportRecipientModalState {
    modifiedSigners: ISignerModel[];
}

export class SignatureFlowReportRecipientModal extends React.Component<ISignerModalProps, SignatureFlowReportRecipientModalState>{
    constructor(props: ISignerModalProps) {
        super(props);
        this.state = {
            modifiedSigners: [],
        }

        this.isCellEditable = this.isCellEditable.bind(this);
        this.updateSignersEmail = this.updateSignersEmail.bind(this);
        this.nonEditableCells = this.nonEditableCells.bind(this);
        this.sequenceOrderType = this.sequenceOrderType.bind(this);
        this.formatWithIcon = this.formatWithIcon.bind(this);
        this.recipientNameFormat = this.recipientNameFormat.bind(this);
    }

    sequenceOrderType(cell: any, row: any) {
        if(this.props.signatureReport && this.props.signatureReport.signingType !=  SigningType.Parallel){     
            if(row.signerType === ClientTypes.Signer && row.isSigned === 0 && (row.delegateeId != 0 && row.delegateeId != null)){
                let delegateeOrderNo = this.props.signatureReport && this.props.signatureReport.signerInfo && this.props.signatureReport.signerInfo.find(x => x.clientId === row.delegateeId)?.order;
                return <div title={cell} className="ellipsis">{delegateeOrderNo}</div>;
                }
                return  row.order > 0 ? <div title={cell} className="ellipsis">{cell}</div> : <div title=""></div>;
        }
        else{
            return<div title=""></div>;
        }    
    }

    recipientType(cell: any, row: any) {
        let signerType: any = `${ClientTypes[row.signerType]}`;;
        return signerType;
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISignerModalProps) {
        if (nextProps.signatureReport && nextProps.signatureReport.signerInfo) {
            this.setState({ modifiedSigners: nextProps.signatureReport.signerInfo })
        }
    }

    isCellEditable(row: any, cellName: any, cellValue: any): boolean {
        if (cellName == 'signerEmail' &&!isValidEmailAddress(cellValue)) {
            VenusNotifier.Warning("Enter a valid email address", null)
            return false
        }
        else if(cellName == 'name'){
            if(isNullOrEmpty(cellValue)){
                VenusNotifier.Warning("Enter a valid recipent name", null)
                return false;
            }
            else if(cellValue && cellValue.length > 100){
                VenusNotifier.Warning("Please Enter Maximum of 100 characters.", null)
                return false;
            }
        }
        return true;
    }

    nonEditableCells(): React.Key[] {
        let rows = this.state.modifiedSigners.filter(x => x.isSigned === 1 || (x.delegateeId !== null ? (x.delegateeId !== 0) : false)).map(y => y.clientId) as React.Key[];
        return rows;
    }

    updateSignersEmail(signers: ISignerModel[]) {
        let duplicateEmails = 0;
        signers.forEach((r: ISignerModel) => {
            if (signers.filter(e => e.signerEmail.toLowerCase() === r.signerEmail.toLowerCase()).length >= 2 &&
                r.signerEmail !== '') {
                duplicateEmails++;
            }
        });
        if (duplicateEmails > 2) {
            VenusNotifier.Warning("Only 2 duplicate emails are allowed ", null)
        }
        else {
            let signatureReport = this.props.signatureReport;
            for (var i = 0; i < signers.length; i++) {
                let index = signatureReport.signerInfo.findIndex(x => x.clientId === signers[i].clientId);
                signatureReport.signerInfo[index] = signers[i];
            }
            let signerDetails = signatureReport.signerInfo.filter(x => x.signerType == ClientTypes.Signer);
            signatureReport.signersEmailAddress = signerDetails.map(x => x.signerEmail).join(',');
            this.props.updateSigners(signatureReport, signers);
        }
    }

    formatWithIcon(cell: any, row: any) {
        if (row.isSigned === 0 && (row.delegateeId === 0 || row.delegateeId === null) &&
            (this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned)) {
            return (<div title='' className='signerEmailRow' style={{ cursor: "pointer" }}><div className='ellipsis' title={row.signerEmail}>{row.signerEmail}</div> <span className='padL05'><PencilIconFill /></span></div>);
        } else { return <div title={row.signerEmail} className="ellipsis">{row.signerEmail}</div>;  }
    }

    recipientNameFormat(cell: any, row: any) {
        if (row.isSigned === 0 && (row.delegateeId === 0 || row.delegateeId === null) &&
            (this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned)) {
            return <div title='' className='signerNameRow' style={{ cursor: "pointer" }}><div className='ellipsis' title={row.name}>{row.name}</div> <span className='padL05'><PencilIconFill /></span></div>;
        } 
        else { 
            return <div title={row.name} className="ellipsis">{row.name}</div>;  
        }
    }

    public render() {
        const signedDetailsColumn = [
            {
                header: 'Recipient Name',
                key: 'name',
                isKey: false,
                dataFormat: this.recipientNameFormat,
                width: '23%',
                toolTip: true,
                editable: this.props.signatureReport !== undefined ?
                (this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                    this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned) ? true : false : false,
                isHidden: false,
                className: 'table-column-header',
            },
            {
                header: 'Email',
                key: 'signerEmail',
                isKey: false,
                dataFormat: this.formatWithIcon,
                width: '37%',
                toolTip: true,
                editable: this.props.signatureReport !== undefined ?
                (this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                    this.props.signatureReport.signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned) ? true : false : false,
                isHidden: false,
                className: 'table-column-header',
            },
            {
                header: 'Sequence Order',
                key: 'order',
                isKey: false,
                dataFormat: this.sequenceOrderType,
                width: '25%',
                toolTip: true,
                editable: false,
                isHidden: false,
                className: 'table-column-header',
            },
            {
                header: 'Type',
                key: 'signerType',
                isKey: false,
                dataFormat: this.recipientType,
                width: '',
                toolTip: true,
                editable: false,
                isHidden: false,
                className: 'table-column-header',
            }
        ];



        let signers: ISignerModel[] = [];

        let signatureStatus: SignatureFlowReportSignatureStatus = SignatureFlowReportSignatureStatus.None;

        if (this.props.signatureReport && this.props.signatureReport.signerInfo) {
            signatureStatus = this.props.signatureReport.signatureStatus;

            let signerRecipients = this.props.signatureReport.signerInfo
                .filter(x => x.signerType == ClientTypes.Signer)
                .sort((a: any, b: any) => {
                    if (a.clientId > b.clientId) return 1;
                    else return -1;
                });

            let ccRecipients = this.props.signatureReport.signerInfo.filter(x => x.signerType == ClientTypes.CC);

            signerRecipients.map((value, index) => {
                signers.push({ ...value });
            });

            if (ccRecipients.length > 0) {
                ccRecipients.map((value, index) => {
                    signers.push({ ...value });
                });
            }
        }


        let modalBody = this.props.signatureReport ?
            (
                <BootstrapTable data={signers} keyField="clientId"
                containerClass='report-table-container modal-scrollable'
                tableHeaderClass='report-header-border'
                striped 
                bordered={false}
                cellEdit={{
                    mode: "click",
                    blurToSave: true,
                    beforeSaveCell: this.isCellEditable,
                    nonEditableRows: this.nonEditableCells
                }} trStyle={(row:ISignerModel) => duplicateRecipient(row,signers) ? {backgroundColor: '#d1ecf1'} : {}}>
                    {signedDetailsColumn.map((value, index) => {
                        return <TableHeaderColumn key={index}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            width={value.width}
                            className={value.className}
                            columnTitle={value.toolTip}
                            editable={value.editable}
                            hidden={value.isHidden}>
                            {value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            ) :
            (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Modal centered={true} className="signed-Details-modal" show={this.props.show} onHide={this.props.onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                   Recipient Details
                   </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
            </Form>
            <Modal.Footer>
                <Button
                    variant="default"
                    className='btn-white modal-footer-button-cancel'
                    onClick={this.props.onCancel}>
                    Cancel
                </Button>
                {signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                    signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned ? <Button
                    variant="info"
                    className=' modal-footer-button-save'
                    onClick={() => this.updateSignersEmail(signers)}>
                    Save
                </Button> : null}

            </Modal.Footer>
        </Modal>
    }
}

export default SignatureFlowReportRecipientModal;