import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { API_BASE_URL } from '../../utils/AppConstants';
import { AppThunkAction } from '../store';
import { actionTypes } from '../store/ActionTypes';
import { handleResponse } from './Library';
import { NotificationAction, StatusType } from '../store/common/NotificationStore';
import * as Constants from '../components/helper/Constants';
import { IRecognizedSigFlowDocument, IReconizedDocumentInfo } from '../models/RecognizedDocument';
import { validateError } from '../components/helper/Validations';

import { logger } from '../../routes/LoggedIn';
import { setDocumentControlGroupIds, setDocumentRadioControlNumber } from '../components/helper/HelperFunctions';

export interface IBlobFile {
    sas: string;
    guid: string;
    storageAccountId: number;
}

export interface RequestSFDocumentAction {
    type: actionTypes.REQUEST_SF_DOCUMENT;
    message: string;
}

export interface ReceiveSFDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_SF_DOCUMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

export interface RequestRecognizedSignatureDocumentInfoAction {
    type: actionTypes.REQUEST_RECOGNIZED_SIGNATURE_DOCUMENTINFO;
}

export interface ReceiveRecognizedSignatureDocumentInfoAction {
    type: actionTypes.RECEIVE_RECOGNIZED_SIGNATURE_DOCUMENTINFO;
    recognizedSignatureFlowDocumentInfo: IReconizedDocumentInfo;
}

export interface DeleteRecognizedDocumentSigntureDataAction {
    documentGuid: string;
    type: actionTypes.DELETE_RECOGNIZED_DOCUMENT_SIGNATUE_DATA;
}

const unloadedState: IBlobFile = {
    sas: '',
    guid: '',
    storageAccountId: 0
};

const unloadedRecognizedSigFlowDocumentState: IReconizedDocumentInfo[] = [];


type DispatchAction = RequestSFDocumentAction |
    ReceiveSFDocumentUploadLinkAction |
    RequestRecognizedSignatureDocumentInfoAction |
    ReceiveRecognizedSignatureDocumentInfoAction |
    DeleteRecognizedDocumentSigntureDataAction;


type KnownAction = DispatchAction | NotificationAction;
export const actionCreators = {
    getSFUploadLink: (url: string, year:number = 0, storageAccountId: number, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url + '/'+ year + '/' + storageAccountId, { credentials: 'include' }).then(handleResponse)
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_SF_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SFDocument.fetchDocumentError,
                    statusType: StatusType.Error,
                    statusCode:error?.status
                })

                logger.trackError(`getSFUploadLink failed for the request having url ${url} with error ${validateError(error)}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_SF_DOCUMENT, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    deleteSFDocument: (documentKey: string, blobLocationYear: number, storageAccountId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const url = `${API_BASE_URL}api/SignatureFlow/Document/DeleteDocumentAsync?documentKey=${documentKey}&blobLocationYear=${blobLocationYear}&storageAccountId=${storageAccountId}`;
        const fetchTask = fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();

                    //bug 53531 fix multiple notification comming  -- hide notification for the call without call back
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SFDocument.deleteDocument,
                        statusType: StatusType.Success
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error, statusCode:error?.status
                });

                logger.trackError(`deleteSFDocument failed for the request having document Key ${documentKey} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    SendDocumentToExtractSignatureControlsAsync: (documentGuid: string, sourceDocumentGuid: string, uploadBlobYear: number, storageAccountId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/Document/SendDocumentToExtractSignatureControlsAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                documentGuid: documentGuid,
                sourceDocumentGuid: sourceDocumentGuid,
                uploadBlobYear: uploadBlobYear,
                storageAccountId: storageAccountId
            })
        })
            .then(handleResponse)
            .then((response) => {
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SFDocument.RecognizedSignatureDocumentInfoError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
            });
        addTask(fetchTask);
    },
    GetRecognizedSignatureDocumentInfoFromBlob: (message: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const request = JSON.parse(message);
        const url = `${API_BASE_URL}api/SignatureFlow/Document/GetRecognizedSignatureDocumentInfoFromBlob?documentGuid=${request.documentGuid}&uploadBlobYear=${request.uploadBlobYear}&storageAccountId=${request.storageAccountId}`;
        const fetchTask = fetch(url, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<IRecognizedSigFlowDocument>)
            .then(function (recognizedSigFlowDocument) {
                if (callback) {
                    callback();
                }
                let recogData: IReconizedDocumentInfo = {
                    documentGuid: '', recognizedDocumentDetails: { uri: '', recognizedPages: [] }
                };
                recogData.documentGuid = request.documentGuid;

                //this is for Esisting Document to set Radio button Option Control Number & gropu Id
                setDocumentRadioControlNumber(recognizedSigFlowDocument.recognizedPages);
                setDocumentControlGroupIds(recognizedSigFlowDocument.recognizedPages);
                
                recogData.recognizedDocumentDetails = recognizedSigFlowDocument;

                dispatch({ type: actionTypes.RECEIVE_RECOGNIZED_SIGNATURE_DOCUMENTINFO, recognizedSignatureFlowDocumentInfo: recogData });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SFDocument.RecognizedSignatureDocumentInfoError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`GetRecognizedSignatureDocumentInfoFromBlob failed for the request having parameters ${JSON.stringify(request)} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_RECOGNIZED_SIGNATURE_DOCUMENTINFO });
    },
    downloadSFDocument: (documentGuid: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL +'api/SignatureFlow/Document/GetSFDocumentLinkAsync/?documentGuid=' + documentGuid, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(function (documentUrl) {
                if (callback) {
                    documentUrl ? callback(documentUrl) : callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SFDocument.fetchDocumentError,
                    statusType: StatusType.Error, statusCode:error?.status
                })
                logger.trackError(`downloadSFDocument failed for the request having documentGuid ${documentGuid} with error ${validateError(error)}`)
            });
        addTask(fetchTask);
    },
    deleteRecognizedDocumentData: (documentKey: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ documentGuid: documentKey, type: actionTypes.DELETE_RECOGNIZED_DOCUMENT_SIGNATUE_DATA });
    }
}

export const reducer: Reducer<IBlobFile> = (state, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_SF_DOCUMENT:
            return {
                ...unloadedState
            };
        case actionTypes.RECEIVE_SF_DOCUMENT_LINK:
            return {
                ...state,
                guid: action.documentGuid,
                sas: action.sasUrl,
            };
    }
    return state || unloadedState;
}

export const fetchSignatureDocumentControlsReducer: Reducer<IReconizedDocumentInfo[]> = (state = unloadedRecognizedSigFlowDocumentState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_RECOGNIZED_SIGNATURE_DOCUMENTINFO:
            return state;
        case actionTypes.RECEIVE_RECOGNIZED_SIGNATURE_DOCUMENTINFO:
            state.length > 0 ? state.push(action.recognizedSignatureFlowDocumentInfo) : state[0] = action.recognizedSignatureFlowDocumentInfo
            return state;
        case actionTypes.DELETE_RECOGNIZED_DOCUMENT_SIGNATUE_DATA:
            if (state.length > 0) {
                state.map(x => x.documentGuid != action.documentGuid);
            }
            return state;
    }
    return state;
}